<script setup lang="ts">
import type { Stop, StopTime } from '@/@types/gtfs';
import { UpdateType, type DisplayedTripUpdates } from '@/store-pinia/trip-updates';
import { computed, type PropType } from 'vue';

const props = defineProps({
  type: {
    required: true,
    type: String as PropType<UpdateType>,
  },
  tripUpdate: {
    required: true,
    type: Object as PropType<DisplayedTripUpdates>,
  },
  stopTimes: {
    default: () => [],
    type: Array as PropType<Array<StopTime>>,
  },

  stops: {
    default: () => {},
    type: Object as PropType<{ [stopId: string]: Stop }>,
  },
});

const eventIcon = computed<string>(() => {
  switch (props.type) {
    case UpdateType.COMMENT:
      return 'fa-comment-dots';
    case UpdateType.DO_NOT_SERVE:
    case UpdateType.DISPLACED_STOPS:
    case UpdateType.TEMPORARY_STOPS:
    case UpdateType.SHAPE:
      return 'fa-arrows-split-up-and-left';
    case UpdateType.DELAY:
    case UpdateType.SPECIFIC_DELAYS:
      return 'fa-stopwatch';
    case UpdateType.TRIP_CANCELED:
      return 'fa-xmark';
    case UpdateType.STOP_INFO:
      return 'fa-comment-dots';
    default:
      return 'fa-bus';
  }
});

function getStopNameBySequence(stopSequence: number): string {
  const stopId = props.stopTimes.find(stopTime => stopTime.stop_sequence === stopSequence)?.stop_id;
  return stopId ? props.stops[stopId].stop_name : '-';
}
</script>

<template>
  <tr class="event-feed-trip-update">
    <!-- Event -->
    <td colspan="4">
      <div
        class="event-feed-trip-update__container"
        :class="{
          'info-style': [UpdateType.STOP_INFO, UpdateType.COMMENT].includes(props.type),
        }"
      >
        <!-- Icon -->
        <font-awesome-icon :icon="eventIcon" class="event-feed-trip-update__icon" />
        <!-- Content -->
        <div v-if="props.type === UpdateType.COMMENT && tripUpdate.comment">
          {{ $t('eventFeed.comment') }} {{ tripUpdate.comment }}
        </div>
        <div v-else-if="props.type === UpdateType.DELAY && tripUpdate.delay">
          <span>
            {{ $t(tripUpdate.delay > 0 ? 'eventFeed.delayColon' : 'eventFeed.advance') }}
            {{ $t('eventFeed.delayMin', [Math.abs(tripUpdate.delay) / 60]) }}
          </span>
        </div>
        <div v-else-if="props.type === UpdateType.DO_NOT_SERVE && tripUpdate.skipped_stop_time_seqs">
          {{ $t('eventFeed.doNotServe', { count: tripUpdate.skipped_stop_time_seqs.length }) }}
          <span v-for="(stop, index) in tripUpdate.skipped_stop_time_seqs" :key="index">
            <span>{{ getStopNameBySequence(stop) }}</span>
            <span v-if="index !== tripUpdate.skipped_stop_time_seqs.length - 1">{{ ', ' }}</span>
          </span>
        </div>
        <div v-else-if="props.type === UpdateType.DISPLACED_STOPS && tripUpdate.displaced_stops">
          {{ $t('eventFeed.displacedStop', { count: tripUpdate.displaced_stops.length }) }}
          <span v-for="(stop, index) in tripUpdate.displaced_stops" :key="index">
            <span>{{ stop.stop_name }}</span>
            <span v-if="index !== tripUpdate.displaced_stops.length - 1">{{ ', ' }}</span>
          </span>
        </div>
        <div v-else-if="props.type === UpdateType.TEMPORARY_STOPS && tripUpdate.temporary_stops">
          {{ $t('eventFeed.temporaryStop', { count: tripUpdate.temporary_stops.length }) }}
          <span v-for="(stop, index) in tripUpdate.temporary_stops" :key="index">
            <span>{{ stop.stop_name }}</span>
            <span v-if="index !== tripUpdate.temporary_stops.length - 1">{{ ', ' }}</span>
          </span>
        </div>
        <div v-else-if="props.type === UpdateType.STOP_INFO && tripUpdate.stop_infos">
          <span v-for="(stopInfo, index) in tripUpdate.stop_infos" :key="index">
            {{ getStopNameBySequence(stopInfo.stop_sequence) }}
            {{ $t('eventFeed.infoStop', [stopInfo.information]) }}
            <span v-if="index !== tripUpdate.stop_infos.length - 1">{{ ', ' }}</span>
          </span>
        </div>
        <div v-else-if="props.type === UpdateType.TRIP_CANCELED">
          {{ $t('eventFeed.canceled') }}
        </div>
        <div v-else-if="props.type === UpdateType.SPECIFIC_DELAYS && tripUpdate.delays">
          {{ $t('eventFeed.individualDelay', { count: tripUpdate.delays.length }) }}
        </div>
        <div v-else-if="props.type === UpdateType.SHAPE">
          {{ $t('eventFeed.shapeModified') }}
        </div>
      </div>
    </td>
  </tr>
</template>

<style lang="scss" scoped>
.event-feed-trip-update {
  &__container {
    display: flex;
    align-content: center;
    margin: 8px 5px 0;
    padding: 5px 10px;
    border: 1px solid $warn;
    border-radius: 8px;
    background-color: $transparent-warn;
    color: $warn;
    font-weight: $font-weight-semi-bold;
    font-size: 12px;

    &.info-style {
      border-color: $blue;
      background-color: $transparent-blue;
      color: $blue;
    }
  }

  &__icon {
    align-self: center;
    height: 14px;
    margin-right: 10px;
  }
}
</style>
