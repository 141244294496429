import { defineAsyncComponent } from 'vue';
import { Permission } from '@/auth';
import {
  DataGrid,
  DataGridColumn,
  SortOrder,
} from '@/components/Table/DataGridVuetify/models/DataGrid.models';
import { dateGtfsFormatToObj } from '@/libs/helpers/dates';
import { GroupRoute } from '@/libs/routing';
import { TripListDataFormatter } from '@/pages/TripsListPage/TripListDataFormatterHelper';
import store from '@/store';

const ClickableCell = defineAsyncComponent(() => import('@/components/Table/cells/ClickableCell.vue'));
const DateCell = defineAsyncComponent(() => import('@/components/Table/cells/DateCell.vue'));
const RegulationStatusCell = defineAsyncComponent(() => import('./RegulationStatusCell.vue'));
const RouteBadge = defineAsyncComponent(() => import('@/components/common/RouteBadge.vue'));

export const DEVIATION_LS_COLUMNS = 'deviationList/columnsSelection';

/** @enum {string} */
export const ColumnKey = {
  FORMATTED_NAME: 'formatted_name',
  ROUTE: 'route',
  DATE: 'service_date',
  REGULATION_INFO: 'regulation',
};

export const getDatagrid = () => {
  const ls = JSON.parse(localStorage.getItem(DEVIATION_LS_COLUMNS));
  const defaultSelected = columnType => !ls || ls.includes(columnType);

  return new DataGrid({
    name: 'deviationListDatagrid',
    searchFields: [ColumnKey.FORMATTED_NAME],
    hasActions: true,
    defaultSortBy: [{ key: ColumnKey.FORMATTED_NAME, order: SortOrder.DESC }],
    columnSelectionLocalStorageKey: DEVIATION_LS_COLUMNS,

    columns: [
      //  TRIP
      new DataGridColumn({
        key: ColumnKey.FORMATTED_NAME,
        title: 'column.trips',
        defaultSelected: defaultSelected(ColumnKey.FORMATTED_NAME),
        selectable: false,
        sortable: true,
        cellBuilder([value, object]) {
          const groupId = store.getters.group._id;
          const to = store.getters.hasPermission(Permission.VIEW_TRIP_VIEW)
            ? {
                name: GroupRoute.TRIP_DETAILED,
                params: { groupId, tripId: object.id },
                query: { date: TripListDataFormatter.getTripDate(object) },
              }
            : null;
          return {
            component: ClickableCell,
            props: {
              highlight: true,
              displayValue: value,
              link: to,
            },
          };
        },
      }),

      // ROUTE
      new DataGridColumn({
        key: ColumnKey.ROUTE,
        title: 'column.route',
        filterable: true,
        defaultSelected: defaultSelected(ColumnKey.ROUTE),
        value: object => object.route.short_name || '',
        cellBuilder([value, object]) {
          const route = object.route;
          return {
            component: RouteBadge,
            props: {
              route: {
                route_color: route.color,
                route_text_color: route.text_color,
              },
              value: route.short_name,
            },
          };
        },
      }),

      // DATE
      new DataGridColumn({
        key: ColumnKey.DATE,
        title: 'Date',
        defaultSelected: true,
        selectable: false,
        sortable: true,
        cellBuilder([value, object]) {
          return {
            component: DateCell,
            props: {
              date: dateGtfsFormatToObj(value),
              options: { dateStyle: 'short' },
            },
          };
        },
      }),

      // REGULATION
      new DataGridColumn({
        key: ColumnKey.REGULATION_INFO,
        title: 'column.regulation',
        sortable: true,
        selectable: false,
        defaultSelected: defaultSelected(ColumnKey.REGULATION_INFO),
        value: object => object.regulation,
        cellBuilder([value, object]) {
          return {
            component: RegulationStatusCell,
            props: {
              regulation: object.updates || {},
            },
          };
        },
      }),
    ],
  });
};
