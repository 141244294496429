import MapboxDraw from '@mapbox/mapbox-gl-draw';
import cloneDeep from 'clone-deep';
import { lineString, point, booleanPointOnLine, nearestPointOnLine } from '@turf/turf';

/**
 * @param {Line} arr
 * @return {Line}
 */
const deduplicateArray = arr =>
  arr.reduce((acc, val) => {
    const prev = acc[acc.length - 1];
    if (!prev || val[0] !== prev[0] || val[1] !== prev[1]) {
      acc.push(val);
    }

    return acc;
  }, /** @type {Line} */ ([]));

/**
 * @param {FeatureEvent} e
 * @return {boolean}
 */
const isLeftClick = e => e.originalEvent && e.originalEvent.button === 0;

/**
 * @param {FeatureEvent} e
 * @return {boolean}
 */
const isRightClick = e => e.originalEvent && e.originalEvent.button === 2;

/**
 * @param {FeatureEvent} e
 * @return {boolean}
 */
const isNewPoint = e => e.featureTarget && e.featureTarget.properties && e.featureTarget.properties.isNew;

/**
 * @param {FeatureEvent} e
 * @return {boolean}
 */
const isLimitPoint = e =>
  e.featureTarget && e.featureTarget.properties && e.featureTarget.properties.isLimitPoint;

/**
 * @param {FeatureEvent} e
 * @return {boolean}
 */
const isShape = e =>
  e.featureTarget &&
  e.featureTarget.properties &&
  ['shape', 'inDeviationLine'].includes(e.featureTarget.properties.id);

/**
 * find nearest point index & get precise position
 * @param {Array<any>} waypointsFeatures
 * @param {[number, number]} longlat
 * @return {{index: number, snapped: Feature<Point, {[key: string]: any;dist: number;index: number;multiFeatureIndex: number;location: number;}> }}
 */
function findNearestPoints(waypointsFeatures, longlat) {
  let nearestLength = null;
  let nearestItem = null;
  waypointsFeatures.forEach((wp, index) => {
    if (index !== 0) {
      var pt = point(longlat);
      var line = lineString([waypointsFeatures[index - 1].coordinates, wp.coordinates]);
      var isPointOnLine = booleanPointOnLine(pt, line, { epsilon: 5e-4 });
      if (isPointOnLine) {
        var snapped = nearestPointOnLine(line, pt, { units: 'kilometers' });
        const l = snapped.properties.dist;
        if (nearestLength === null || l < nearestLength) {
          nearestLength = l;
          nearestItem = { index: index, snapped: snapped };
        }
      }
    }
  });
  return nearestItem;
}

/**
 * @param {Array<Point>} waypoints
 * @return {Promise<Array<Line>>}
 */
async function mapboxDirections(state, waypoints) {
  const strCoordinates = waypoints.map(p => p.map(round6).join(',')).join(';');
  const params = Object.entries({
    geometries: 'polyline6',
    overview: 'full',
    continue_straight: 'false',
    steps: 'true',
    access_token: 'pk.eyJ1IjoicHlzYWUiLCJhIjoiY2s0Y2hrYTlxMG50ODNra2R6ZGVudTR5aiJ9.sccZsmomeJ-zdW21vHcSYQ',
  })
    .map(e => e.join('='))
    .join('&');
  const response = await fetch(
    `https://api.mapbox.com/directions/v5/mapbox/driving/${strCoordinates}.json?${params}`,
  );
  const result = await response.json();

  const pointsResults = result.routes[0].legs.map(
    /** @param {{steps: Array<{geometry: string}>}} leg */
    leg =>
      deduplicateArray(
        leg.steps.reduce(
          (acc, step) => acc.concat(polylineDecode(step.geometry, 6).map(p => p.reverse())),
          [],
        ),
      ),
  );
  state.newLineFeature.setCoordinates(pointsResults.flat(1));

  return pointsResults;
}

/**
 * @param {string} str
 * @param {number} precision
 * @return {Line}
 */
function polylineDecode(str, precision) {
  // Decode str to int array
  let strIndex = 0;
  const values = [];
  while (strIndex < str.length) {
    let byte;
    let result = 0;
    let shift = 0;
    do {
      byte = str.charCodeAt(strIndex++) - 63;
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);
    values.push(result & 1 ? ~(result >> 1) : result >> 1);
  }

  const factor = Math.pow(10, precision === undefined ? 5 : precision);
  const dim = 2;
  const coords = [];
  // Deltas -> absolute values
  for (let i = dim; i < values.length; i++) values[i] += values[i - dim];
  // Group & scale
  for (let i = 0; i < values.length; i += dim) coords.push(values.slice(i, i + dim).map(n => n / factor));

  return coords;
}

/**
 * @param {number} n
 * @return {number}
 */
const round6 = n => Math.round(n * 1e6) / 1e6;

/**
 * @param {State} state
 * @param {Array<number>} [updateIndexes]
 */
async function updateCoordinates(state, updateIndexes) {
  let start = 0;
  /** @type {Array<Promise<Array<Line>>|Array<Line>>} */
  const promises = [];

  const consecutiveSnapWaypoints = (() => {
    let queuedPoints = [];

    const dequeue = () => {
      if (queuedPoints.length > 0) {
        // Dequeue coordinates generation
        if (state.inLastPointModification) {
          // remove 1 coord in case last point to avoid re-write previous path
          queuedPoints.shift();
        }
        promises.push(mapboxDirections(state, queuedPoints.splice(0)));
      }
    };

    return {
      dequeue,

      /** @param {Array<Point>} segment */
      queue(segment) {
        if (queuedPoints.length === 0) {
          queuedPoints.push(segment[0]);
        }
        queuedPoints.push(segment[1]);

        if (queuedPoints.length >= 25) {
          dequeue();
        }
      },
    };
  })();

  while (start < state.waypointsFeatures.length - 1) {
    const points = state.waypointsFeatures.slice(start, start + 2);
    /** @type {Array<Point>} */
    const segment = points.map(p => p.getCoordinates());

    if (points[1].properties.snapRoad) {
      if (!updateIndexes || updateIndexes.includes(start + 1)) {
        // Queue coordinates generation
        consecutiveSnapWaypoints.queue(segment);
      } else {
        consecutiveSnapWaypoints.dequeue();

        // Keep previously generated coordinates
        /** @type {Line} */
        const origCoords = state.lineFeature.getCoordinates();
        promises.push([
          origCoords.slice(points[0].properties.coord_index, points[1].properties.coord_index + 1),
        ]);
      }
    } else {
      consecutiveSnapWaypoints.dequeue();
      promises.push([segment]);
    }

    start += 1;
  }

  consecutiveSnapWaypoints.dequeue();
  const groupedWaypointLines = await Promise.all(promises);
  state.waypointsFeatures[0].setProperty('coord_index', 0);
  const coordinates = [];
  groupedWaypointLines
    .reduce((acc, group) => acc.concat(group), [])
    .forEach((waypointLine, index) => {
      // Remove a point to connect segments, preferably on the road (not useful in last point case)
      if (coordinates.length > 0 && !state.inLastPointModification) {
        state.waypointsFeatures[index].properties.snapRoad ? waypointLine.shift() : coordinates.pop();
      }

      coordinates.push(...waypointLine);
      state.waypointsFeatures[index + 1].setProperty('coord_index', coordinates.length - 1);
    });

  state.lineFeature.setCoordinates(coordinates);
}

const DrawShape = {};

// Private API

/**
 * Add a new temporary waypoint Feature in list waypointsFeatures
 * @param {State} state
 * @param {Point} coordinates
 */
DrawShape.addTempWaypoint = function (state, coordinates, index) {
  state.waypointsFeatures.splice(
    index,
    0,
    this.createWaypoint({
      coordinates,
      snapRoad: state.getSnapRoad(),
    }),
  );
};

/**
 * Add a new waypoint Feature in list newWaypointsFeatures
 * @param {State} state
 * @param {Point} coordinates
 */
DrawShape.addNewWaypoint = function (state, coordinates) {
  state.newWaypointsFeatures.push(
    this.createWaypoint({
      coordinates,
      snapRoad: state.getSnapRoad(),
    }),
  );
};

/**
 * @param {Waypoint} waypoint
 * @return {import('@types/mapbox__mapbox-gl-draw').DrawFeature}
 */
DrawShape.createWaypoint = function (waypoint) {
  const feature = this.newFeature({
    type: MapboxDraw.constants.geojsonTypes.FEATURE,
    properties: {
      snapRoad: waypoint.snapRoad,
    },
    geometry: {
      type: MapboxDraw.constants.geojsonTypes.POINT,
      coordinates: waypoint.coordinates,
    },
  });
  this.addFeature(feature);
  this.select(feature.id);
  this.doRender(feature.id);
  return feature;
};

/**
 * @param {State} state
 * @param {Array<number>} [updateIndexes]
 */
DrawShape.refreshRoute = async function (state, updateIndexes) {
  state.isRefreshing = true;
  this.updateUIClasses({ mouse: 'wait' });
  if (state.waypointsFeatures.length >= 2) {
    await updateCoordinates(state, updateIndexes);
  } else {
    state.lineFeature.setCoordinates([]);
  }

  // Couldn't find documented API to trigger the actual render asynchronously
  this._ctx.store.render();
  state.isRefreshing = false;
};

/**
 * Delete a new waypoint used for modify shape
 * @param {State} state
 * @param {FeatureEvent} e
 */
DrawShape.removeWaypoint = function (state, e) {
  const index1 = state.waypointsFeatures.findIndex(wp => wp.id === e.featureTarget.properties.id);
  const index = state.newWaypointsFeatures.findIndex(wp => wp.id === e.featureTarget.properties.id);

  if (index !== -1) {
    state.waypointsFeatures.splice(index1, 1);
    state.newWaypointsFeatures.splice(index, 1);
    this.deleteFeature(e.featureTarget.properties.id);
    this.editShapeLocally(state);
  }
};

/**
 * Create a waypointFeature in list to deviate part selection
 * @param {State} state
 * @param {FeatureEvent} e
 */
DrawShape.setSelectionPointOnShape = function (state, e) {
  const nearestItem = findNearestPoints(state.waypointsFeatures, [e.lngLat.lng, e.lngLat.lat]);
  if (nearestItem) {
    this.addTempWaypoint(state, nearestItem.snapped.geometry.coordinates, nearestItem.index);
    this.selectPoints(state, e, nearestItem.index);
  }
};

/**
 * Select points in list to deviate part selection & handle render of this part
 * @param {State} state
 * @param {FeatureEvent} e
 * @param {number?} shapeClickIndex
 */
DrawShape.selectPoints = function (state, e, shapeClickIndex = null) {
  const clickedIndex =
    shapeClickIndex || state.waypointsFeatures.findIndex(wp => wp.id === e.featureTarget.properties.id);

  if (state.selectedPoints.length > 0) {
    const previousSelectedPointState = cloneDeep(state.selectedPoints);
    const previousIndex = state.waypointsFeatures.findIndex(wp => wp.id === state.selectedPoints[0]);
    let elements = [];
    const pointIndexes =
      clickedIndex > previousIndex ? [previousIndex, clickedIndex] : [clickedIndex, previousIndex];
    elements = state.waypointsFeatures.slice(pointIndexes[0], pointIndexes[1] + 1);
    state.selectedPoints = elements.map(wp => wp.id);

    if (state.selectedPoints.length > 0) {
      const coords = state.waypointsFeatures.map(wp => wp.coordinates);
      const firstCoordIndex = coords.findIndex(
        coord => coord === state.waypointsFeatures[pointIndexes[0]].coordinates,
      );
      const secondCoordIndex = coords.findIndex(
        coord => coord === state.waypointsFeatures[pointIndexes[1]].coordinates,
      );
      state.inDeviationLineFeature.setCoordinates(coords.slice(firstCoordIndex, secondCoordIndex + 1));
      state.displayedSelectedPoints = [
        state.waypointsFeatures[pointIndexes[0]].id,
        state.waypointsFeatures[pointIndexes[1]].id,
      ];
    }
    // update previous & new elements potentially selected
    [...previousSelectedPointState, ...elements.map(wp => wp.id)].forEach(wpId => this.doRender(wpId));
  } else {
    const elementId = state.waypointsFeatures[clickedIndex].id;
    this.doRender(elementId);
    state.selectedPoints.push(elementId);
    state.displayedSelectedPoints = [elementId];
    state.inDeviationLineFeature.setCoordinates([]);
  }
  state.inDeviationMode = true;
};

/**
 * @param {State} state
 * @param {number} index
 */
DrawShape.startDragging = function (state, index) {
  this.map.dragPan.disable();
  state.canDrag = true;
  state.dragIndex = index;
};

/** @param {State} state */
DrawShape.stopDragging = function (state) {
  this.map.dragPan.enable();
  state.canDrag = false;
  state.dragMoving = false;
  state.dragIndex = null;
};

DrawShape.deletePreviousPartAndEditShapeLocally = function (state, coordinates) {
  this.addNewWaypoint(state, coordinates);

  const indexes = [];
  if (state.selectedPoints.length > 0) {
    // detect if selected point is last (specific case)
    if (
      state.selectedPoints.length === 1 &&
      state.waypointsFeatures.findIndex(wp => wp.id === state.selectedPoints[0]) ===
        state.waypointsFeatures.length - 1
    ) {
      state.inLastPointModification = true;
    } else {
      state.inLastPointModification = false;
    }
    state.selectedPoints.forEach(id => {
      const index = state.waypointsFeatures.findIndex(wp => wp.id === id);
      if (index !== -1) indexes.push(index);
    });
    state.selectedPoints.forEach(id => {
      const index = state.waypointsFeatures.findIndex(wp => wp.id === id);
      if (index !== -1) {
        if (state.selectedPoints[state.selectedPoints.length - 1] === id) {
          state.waypointsFeatures[index].properties.snapRoad = true;
        } else if (state.selectedPoints[0] === id) {
          this.deleteFeature(state.waypointsFeatures.splice(index, 1)[0].id);
          state.waypointsFeatures[index - 1].properties.snapRoad = true;
          state.displayedSelectedPoints[0] = state.waypointsFeatures[index - 1].id;
          this.doRender(state.selectedPoints[0]);
        } else {
          // if not last point, delete
          this.deleteFeature(state.waypointsFeatures.splice(index, 1)[0].id);
        }
      }
    });
    state.startIndexUpdate = Math.min(...indexes);
    state.selectedPoints = [];

    state.initialWaypointFeaturesWithoutSelection = cloneDeep(state.waypointsFeatures);
  }
  this.editShapeLocally(state);
};

DrawShape.editShapeLocally = async function (state) {
  let maxIndex = state.startIndexUpdate + state.newWaypointsFeatures.length + 1;
  state.waypointsFeatures = cloneDeep(state.initialWaypointFeaturesWithoutSelection);
  if (state.inLastPointModification) {
    state.waypointsFeatures.push(...state.newWaypointsFeatures);
  } else state.waypointsFeatures.splice(state.startIndexUpdate, 0, ...state.newWaypointsFeatures);
  var indexToUpdate = [];
  for (var i = state.startIndexUpdate; i <= maxIndex; i++) {
    indexToUpdate.push(i);
  }
  await this.refreshRoute(state, indexToUpdate);

  this.map.fire(MapboxDraw.constants.events.UPDATE, {
    action: MapboxDraw.constants.updateActions.CHANGE_COORDINATES,
    features: [state.lineFeature.toGeoJSON()],
  });

  setTimeout(() => {
    this.doRender('newLineFeature');
  }, 100);
};

// Public API

/** @param {Options} opts */
DrawShape.onSetup = function (opts) {
  opts = Object.assign(
    {
      getSnapRoad: () => false,
      waypoints: [],
    },
    opts,
  );

  this.clearSelectedFeatures();

  /** @type {State} */
  const state = {
    canDrag: false,
    dragIndex: null,
    dragMoving: false,
    getSnapRoad: opts.getSnapRoad,
    isRefreshing: false,

    inDeviationMode: false,
    selectedPoints: [],
    displayedSelectedPoints: [],
    startIndexUpdate: null,
    inLastPointModification: false,

    initialWaypointFeaturesWithoutSelection: null,
    initialCoords: opts.waypoints.map(wp => wp.coordinates),

    lineFeature: this.newFeature({
      type: MapboxDraw.constants.geojsonTypes.FEATURE,
      id: 'shape',
      properties: {},
      geometry: {
        type: MapboxDraw.constants.geojsonTypes.LINE_STRING,
        coordinates: [],
      },
    }),
    inDeviationLineFeature: this.newFeature({
      type: MapboxDraw.constants.geojsonTypes.FEATURE,
      id: 'inDeviationLine',
      properties: {},
      geometry: {
        type: MapboxDraw.constants.geojsonTypes.LINE_STRING,
        coordinates: [],
      },
    }),
    newLineFeature: this.newFeature({
      type: MapboxDraw.constants.geojsonTypes.FEATURE,
      id: 'newLineFeature',
      properties: {},
      geometry: {
        type: MapboxDraw.constants.geojsonTypes.LINE_STRING,
        coordinates: [],
      },
    }),

    waypointsFeatures: opts.waypoints.map(wp => this.createWaypoint(wp)),
    newWaypointsFeatures: [],

    limitWaypointsIds: [],
  };
  state.limitWaypointsIds = [
    state.waypointsFeatures[0].id,
    state.waypointsFeatures[state.waypointsFeatures.length - 1].id,
  ];
  this.addFeature(state.lineFeature);
  this.addFeature(state.inDeviationLineFeature);
  this.addFeature(state.newLineFeature);
  this.select(state.lineFeature.id);
  this.refreshRoute(state);

  return state;
};

/** @param {State} state */
DrawShape.onStop = function (state) {
  this.deleteFeature(state.waypointsFeatures.map(wp => wp.id));
};

/**
 * @param {State} state
 * @param {FeatureEvent} e
 */
DrawShape.onClick = function (state, e) {
  this.stopDragging(state);
  if (isShape(e) && isLeftClick(e)) {
    return this.setSelectionPointOnShape(state, e);
  }
  if (isLimitPoint(e) && isLeftClick(e)) return this.selectPoints(state, e);
  if (isLeftClick(e) && state.inDeviationMode)
    return this.deletePreviousPartAndEditShapeLocally(state, e.lngLat.toArray());
  if (isNewPoint(e) && isRightClick(e)) {
    return this.removeWaypoint(state, e);
  }
};

/**
 * @param {State} state
 * @param {FeatureEvent} e
 */
DrawShape.onDrag = function (state, e) {
  if (!state.canDrag) return this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.NONE });

  state.dragMoving = true;
  // Only can drag (to move) a new waypoint to edit a shape
  state.newWaypointsFeatures[state.dragIndex].setCoordinates([e.lngLat.lng, e.lngLat.lat]);
};

/**
 * @param {State} state
 * @param {FeatureEvent} e
 */
DrawShape.onMouseMove = function (state, e) {
  if (state.isRefreshing) return this.updateUIClasses({ mouse: 'wait' });

  if (isNewPoint(e)) return this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.MOVE });

  if (isShape(e)) return this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.POINTER });

  if (state.inDeviationMode) return this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.ADD });

  this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.DRAG });
};

/**
 * @param {State} state
 * @param {FeatureEvent} e
 */
DrawShape.onMouseDown = function (state, e) {
  if (!isLeftClick(e)) return;
  if (isNewPoint(e)) {
    const index = state.newWaypointsFeatures.findIndex(wp => wp.id === e.featureTarget.properties.id);
    return this.startDragging(state, index);
  }
};

/**
 * @param {State} state
 * @param {FeatureEvent} e
 */
DrawShape.onMouseUp = function (state, e) {
  if (state.dragMoving) {
    this.editShapeLocally(state);
  }

  this.stopDragging(state);
};

/**
 * @param {State} state
 * @param {GeoJSON.Feature} geojson
 * @param {(feature: GeoJSON.Feature) => void} display
 */
DrawShape.toDisplayFeatures = function (state, geojson, display) {
  // set points as active (only if in displayedSelectedPoints)
  geojson.properties.active = state.displayedSelectedPoints.includes(geojson.properties.id)
    ? MapboxDraw.constants.activeStates.ACTIVE
    : MapboxDraw.constants.activeStates.INACTIVE;

  // set lines as active
  if (geojson.geometry.type === MapboxDraw.constants.geojsonTypes.LINE_STRING) {
    geojson.properties.active = MapboxDraw.constants.activeStates.ACTIVE;
  }

  // set of isNew for new waypoints stops
  if (state.newWaypointsFeatures.map(wp => wp.id).includes(geojson.properties.id))
    geojson.properties.isNew = true;

  // set of limits points display
  if (state.limitWaypointsIds.includes(geojson.properties.id)) geojson.properties.isLimitPoint = true;

  // set of "isSelection property" for deviation line display
  if (geojson.properties.id === 'inDeviationLine') geojson.properties.isSelection = true;

  // set of "isNewLine property" for new line display
  if (geojson.properties.id === 'newLineFeature') geojson.properties.isNewLine = true;

  display(geojson);
};

export default DrawShape;

/**
 * @typedef {Object} FeatureEvent
 * @property {?{properties: TargetProperties}} featureTarget
 * @property {import('mapbox-gl').LngLat} lngLat
 * @property {MouseEvent} originalEvent
 */

/** @typedef {Array<Point>} Line */

/**
 * @typedef {Object} Options
 * @property {() => boolean} getSnapRoad
 * @property {Array<Waypoint>} waypoints
 */

/** @typedef {Array<number>} Point */

/**
 * @typedef {Object} State
 * @property {boolean} canDrag
 * @property {?number} dragIndex
 * @property {boolean} dragMoving
 * @property {() => boolean} getSnapRoad
 * @property {boolean} isRefreshing
 * @property {boolean} inDeviationMode
 * @property {Array<string>} selectedPoints
 * @property {Array<string>} displayedSelectedPoints
 * @property {number} startIndexUpdate
 * @property {boolean} inLastPointModification
 * @property {Array<import('@types/mapbox__mapbox-gl-draw').DrawFeature>?} initialWaypointFeaturesWithoutSelection
 * @property {Array<[number, number]>} initialCoords
 * @property {import('@types/mapbox__mapbox-gl-draw').DrawFeature} lineFeature
 * @property {import('@types/mapbox__mapbox-gl-draw').DrawFeature} inDeviationLineFeature
 * @property {import('@types/mapbox__mapbox-gl-draw').DrawFeature} newLineFeature
 * @property {Array<import('@types/mapbox__mapbox-gl-draw').DrawFeature>} waypointsFeatures
 * @property {Array<import('@types/mapbox__mapbox-gl-draw').DrawFeature>} newWaypointsFeatures
 * @property {Array<string>} limitWaypointsIds
 */

/**
 * @typedef {Object} TargetProperties
 * @property {string} id
 * @property {string} meta
 * @property {string} 'meta:type'
 * @property {string} [coord_path]
 * @property {boolean} [isNewLine]
 * @property {boolean} [isSelection]
 * @property {boolean} [isLimitPoint]
 * @property {boolean} [isNew]

 */

/**
 * @typedef {Object} Waypoint
 * @property {boolean} snapRoad
 * @property {Array<number>} coordinates
 */
