export function getDrawTheme(routeColor) {
  return [
    {
      id: 'gl-draw-line-inactive',
      type: 'line',
      filter: ['all', ['==', 'active', 'false'], ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#aaa',
        'line-opacity': 0.7,
        'line-width': 4,
      },
    },
    {
      id: 'gl-draw-line-active-background',
      type: 'line',
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#000',

        'line-width': 5,
      },
    },
    {
      id: 'gl-draw-line-active',
      type: 'line',
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': [
          'case',
          ['to-boolean', ['get', 'isSelection']],
          '#ff0000',
          ['to-boolean', ['get', 'isNewLine']],
          '#F99C49',
          routeColor,
        ],

        'line-width': 4,
      },
    },
    {
      id: 'gl-draw-line-active-arrows',
      type: 'symbol',
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
      layout: {
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
        'icon-image': 'shape_arrow',
        'icon-size': 0.25,
        'symbol-placement': 'line',
        'symbol-spacing': 30,
      },
    },
    {
      id: 'gl-draw-midpoint-stroke',
      type: 'circle',
      filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
      paint: {
        'circle-color': '#000',
        'circle-opacity': 0.5,
        'circle-radius': 6,
      },
    },
    {
      id: 'gl-draw-midpoint',
      type: 'circle',
      filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
      paint: {
        'circle-color': '#fff',
        'circle-opacity': 0.7,
        'circle-radius': 5,
      },
    },
    {
      id: 'gl-draw-point-stroke-inactive',
      type: 'circle',
      filter: ['any', ['==', 'isNew', true], ['==', 'isLimitPoint', true]],
      paint: {
        'circle-color': '#000',
        'circle-radius': 6,
        'circle-opacity': 0.3,
      },
    },
    {
      id: 'gl-draw-point-inactive',
      type: 'circle',
      filter: ['any', ['==', 'isNew', true], ['==', 'isLimitPoint', true]],
      paint: {
        'circle-color': ['case', ['to-boolean', ['get', 'isNew']], '#66f542', '#0384fc'],
        'circle-radius': 5,
        'circle-opacity': 1,
      },
    },
    {
      id: 'gl-draw-point-stroke-active',
      type: 'circle',
      filter: ['all', ['==', '$type', 'Point'], ['==', 'active', 'true'], ['!=', 'meta', 'midpoint']],
      paint: {
        'circle-color': '#000',
        'circle-radius': 6,
      },
    },
    {
      id: 'gl-draw-point-active',
      type: 'circle',
      filter: ['all', ['==', '$type', 'Point'], ['!=', 'meta', 'midpoint'], ['==', 'active', 'true']],
      paint: {
        'circle-color': '#fff',
        'circle-radius': 5,
      },
    },
  ];
}
