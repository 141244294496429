enum NotificationPermission {
  DENIED = 'denied',
  GRANTED = 'granted',
  DEFAULT = 'default',
}

export const webNotifications = {
  requestPermission: () => {
    if (Notification.permission !== NotificationPermission.GRANTED) {
      Notification.requestPermission();
    }
  },
  send: (title: string, options: NotificationOptions) => {
    if (Notification.permission === NotificationPermission.GRANTED) {
      const notification: Notification = new Notification(title, options);
      notification.onclick = event => {
        event.preventDefault();
        window.focus();
        notification.close();
      };
    }
  },
};
