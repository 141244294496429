<script setup lang="ts">
import { type EditedShape } from '@/@types/mapbox';
import { ref, watch, type PropType } from 'vue';

import Btn from '@/components/ui/Btn.vue';
import DrawShapeMode from '@/libs/mapbox/draw-shape-mode';
import { getDrawTheme } from '@/libs/mapbox/draw-theme.js';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import { useTripUpdates } from '@/store-pinia/trip-updates';
import { MapboxHelper } from './mapboxHelper';

const tripUpStore = useTripUpdates();

const mapboxDraw = ref<MapboxDraw>();
const prevent = (e: { preventDefault: () => any }) => e.preventDefault();

const props = defineProps({
  map: {
    type: Object as PropType<mapboxgl.Map>,
    required: true,
  },
  editedShape: {
    type: Object as PropType<EditedShape>,
    required: true,
  },
  routeColor: {
    type: String,
    default: '#ff0',
  },
});

const editedShapeShadowId = ref<string | null>(null);
const coordinatesInEdition = ref<boolean>(false);
const editedCoordinates = ref<Array<[number, number]>>();

watch(
  () => props.editedShape,
  async (_, oldEditedShape) => {
    // Stop edition
    if (props.editedShape === null) {
      props.map.off('contextmenu', prevent);
      if (mapboxDraw.value) {
        props.map.removeControl(mapboxDraw.value as any);
      }
      return;
    }
    props.map.on('contextmenu', prevent);

    // Init draw control and callbacks on first use
    if (!mapboxDraw.value) {
      mapboxDraw.value = new MapboxDraw({
        displayControlsDefault: false,
        boxSelect: true,
        styles: getDrawTheme(props.routeColor),
        modes: {
          simple_select: MapboxDraw.modes.simple_select,
          draw_shape: DrawShapeMode as any,
        },
      });

      // Shape changed
      props.map.on('draw.update', e => {
        if (e.action === 'change_coordinates') {
          coordinatesInEdition.value = true;
          editedCoordinates.value = e.features[0].geometry.coordinates;
        }
      });
    }

    if (!oldEditedShape) {
      props.map.addControl(mapboxDraw.value as any, 'top-left');
    }
  },
  { immediate: true },
);

watch(
  () => props.editedShape.waypoints,
  () => {
    if (!mapboxDraw.value || !props.editedShape?.waypoints) return;

    // Keep only last previous draw
    if (editedShapeShadowId.value) {
      mapboxDraw.value.delete(editedShapeShadowId.value);
    }
    const prevShape: GeoJSON.Feature<GeoJSON.LineString> = mapboxDraw.value.get(
      'shape',
    ) as GeoJSON.Feature<GeoJSON.LineString>;
    if (prevShape && prevShape.geometry.coordinates.length >= 2) {
      delete prevShape.id;
      editedShapeShadowId.value = mapboxDraw.value.add(prevShape)[0];
    }

    mapboxDraw.value.changeMode('draw_shape', {
      getSnapRoad: () => props.editedShape.snapRoad,
      waypoints: props.editedShape.waypoints,
    });
  },
  { immediate: true },
);

function resetShapeWaypoints() {
  if (!mapboxDraw.value || !props.editedShape?.waypoints) return;

  // Keep only last previous draw
  if (editedShapeShadowId.value) {
    mapboxDraw.value.delete(editedShapeShadowId.value);
  }
  editedShapeShadowId.value = null;

  mapboxDraw.value.changeMode('draw_shape', {
    getSnapRoad: () => props.editedShape.snapRoad,
    waypoints: props.editedShape.waypoints,
  });

  editedCoordinates.value = undefined;
  coordinatesInEdition.value = false;
}

function close() {
  tripUpStore.inShapeEdition = false;
  if (mapboxDraw.value && editedShapeShadowId.value) mapboxDraw.value.delete(editedShapeShadowId.value);
  props.map.removeControl(mapboxDraw.value as any);

  // Handle layers orders on specific edition case
  setTimeout(() => {
    MapboxHelper.defaultLayerOrderReset(props.map);
  }, 300);
}

function confirmDeviation() {
  if (mapboxDraw.value && editedCoordinates.value) {
    coordinatesInEdition.value = false;
    mapboxDraw.value.changeMode('draw_shape', {
      getSnapRoad: () => props.editedShape.snapRoad,
      waypoints: editedCoordinates.value.map(coordinates => ({ coordinates, snapRoad: false })),
    });
  }
}

function saveShape() {
  tripUpStore.editedShape = editedCoordinates.value;
  close();
}
</script>

<template>
  <div class="mapbox-shape-edition">
    <v-card elevation="0" class="mapbox-shape-edition__card">
      <v-card-item class="mapbox-shape-edition__card__header">
        <v-icon size="x-small">fa:fas fa-pen</v-icon>
        <span class="mapbox-shape-edition__card__title">{{ $t('shapeEdition') }}</span>
        <Btn type="secondary" @click="resetShapeWaypoints()">
          <font-awesome-icon icon="fa-rotate-right" />
          <span>{{ $t('reset') }}</span>
        </Btn>
      </v-card-item>

      <v-divider />
      <v-card-item>
        <Btn type="primary" :disabled="!coordinatesInEdition" @click="confirmDeviation()">
          <span>{{ $t('validate') }}</span>
        </Btn>
      </v-card-item>
      <v-card-actions>
        <Btn type="secondary" @click="close()">
          <span>{{ $t('cancel') }}</span>
        </Btn>
        <Btn type="primary" :disabled="!editedCoordinates || coordinatesInEdition" @click="saveShape()">
          <font-awesome-icon icon="fa-check" />
          <span>{{ $t('save') }}</span>
        </Btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<style lang="scss">
.mapbox-shape-edition {
  &__card {
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 10;
    display: block;

    &__header {
      .v-card-item__content {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }

    &__title {
      font-weight: 600;
      font-size: 16px;
    }
  }

  .v-card-actions {
    button {
      width: 100%;
    }
  }
}
</style>

<i18n locale="fr">
{
  "shapeEdition": "Edition de tracé",
  "reset": "Réinitialiser",
  "validate": "Valider"
}
</i18n>

<i18n locale="en">
{
  "shapeEdition": "Shape edition",
  "reset": "Reset",
  "validate": "validate"
}
</i18n>
