<script setup lang="ts">
import { computed } from 'vue';

import ModalBlockedView from './ModalBlockedView.vue';

const props = defineProps({
  backgroundImg: {
    type: String,
    default: () => '',
  },
  modalImg: {
    type: String,
    default: () => null,
  },
  text: {
    type: Array<Array<string>>,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
});

const bgImageSource = computed(
  () => `url(${new URL(`/src/assets/img/${props.backgroundImg}.jpg`, import.meta.url).href})`,
);
</script>

<template>
  <div id="blockedView" class="blocked-view">
    <ModalBlockedView :title="title" :text="text" :modal-img="modalImg" />
  </div>
</template>

<style lang="scss">
.blocked-view {
  $blocked-height: calc(100vh - $navbar-top);

  height: $blocked-height;
  background: v-bind('bgImageSource') no-repeat center center / contain;

  // Necessary line - problem without it
  filter: blur(0);

  .v-overlay__scrim {
    background: none !important;
  }
}
</style>
