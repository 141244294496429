import type { StopTime } from '@/@types/gtfs';
import type { Waypoint } from '@/libs/mapbox/draw-shape-mode';
import { FstType } from '@/store-pinia/trip-updates';

export const SHAPES_ARROW_LAYER_ID = 'shapesArrowLayer';
export const SHAPES_HIGHLIGHT_FG_LAYER_ID = 'shapesHighlightFgLayer';
export const SHAPES_HIGHLIGHT_BG_LAYER_ID = 'shapesHighlightBgLayer';
export const SHAPES_FG_LAYER_ID = 'shapesFgLayer';
export const SHAPES_BG_LAYER_ID = 'shapesBgLayer';
export const SHAPES_EDITED_FG_LAYER_ID = 'shapesEditedFgLayer';
export const SHAPES_EDITED_BG_LAYER_ID = 'shapesEditedBgLayer';

export const STATIONS_ICON_LAYER_ID = 'stationsIconLayer';
export const STOPS_ICON_LAYER_ID = 'stopsIconLayer';
export const STOPS_ZONE_LAYER_ID = 'stopsZoneLayer';
export const STATIONS_LABEL_LAYER = 'stationsLabelLayer';
export const STOPS_LABEL_LAYER = 'stopsLabelLayer';

export const UNSERVED_STOP_LAYER = 'unservedStopLayer';
export const UNSERVED_STATION_LAYER = 'unservedStationLayer';
export const DEVIATION_STOP_LAYER = 'deviationStopLayer';
export const DEVIATION_STATION_LAYER = 'deviationStationLayer';

export enum HighlightType {
  NONE = 0,
  LESS = 1,
  MORE = 2,
}

export interface MapTrip {
  id: string;
  highlight: HighlightType;
}

export interface ShapeData {
  color: string;
  geometry: GeoJSON.LineString;
  id: string;
}

export interface MapStop {
  id: string;
  highlight: boolean;
  unserved?: boolean;
  stop_sequence: number;
  deviation?: boolean;
  editionHighlight?: boolean;
  type?: FstType;
}

export interface StopsOptions {
  stopsMarkers: boolean;
  stopsZones: boolean;
  stationsMarkers: boolean;
  stopsBigMarkers: boolean;
  showUnserved: boolean;
  stopSelectorData?: StopTime[];
}

export interface DisplayOptions {
  linesShapes: boolean;
  stopsZones: boolean;
  traffic: boolean;
  vehicles: boolean;
  stations: boolean;
  stops: boolean;
}

export interface LayerOptions extends DisplayOptions {
  vehiclesLabels: string;
}

export interface EditedShape {
  id: string;
  coordinates: Array<Array<number>>;
  snapRoad: boolean;
  waypoints: Array<Waypoint>;
}
