<template>
  <div class="mapbox-devices"></div>
</template>

<script>
import deepmerge from 'deepmerge';
import { MapboxHelper } from '@/components/map/mapboxHelper';
import {
  LAYER_BEARING,
  LAYER_COLOR,
  DEVICE_DELAY_COLOR,
  LAYER_ICON,
  LAYER_ROUTE_COLOR,
} from './map-const.js';
import { dateGtfsFormatToObj, getISODate } from '@/libs/helpers/dates';
import { AppUrl } from '@/api';

import { GroupRoute } from '@/libs/routing';

const BEARING_ICON = new URL(`../../assets/img/bearing.png`, import.meta.url).href;
const BUS_ICON = new URL(`../../assets/img/bus-op.png`, import.meta.url).href;

const DEVICES_SOURCE_ID = 'devicesSource';

const DEVICES_LABEL_LAYER_ID = 'devicesLabelLayer ';
const DEVICES_HIGHLIGHT_COLOR_LAYER_ID = 'devicesHighlightColorLayer';
const DEVICES_HIGHLIGHT_ROUTE_COLOR_LAYER_ID = 'devicesHighlightRouteColorLayer';
const DEVICES_HIGHLIGHT_ICON_LAYER_ID = 'devicesHighlightIconLayer';
const DEVICES_HIGHLIGHT_BEARING_LAYER_ID = 'devicesHighlightBearingLayer';
const DEVICES_COLOR_LAYER_ID = 'devicesColorLayer';
const DEVICES_ROUTE_COLOR_LAYER_ID = 'devicesRouteColorLayer';
const DEVICES_ICON_LAYER_ID = 'devicesIconLayer';
const DEVICES_BEARING_LAYER_ID = 'devicesBearingLayer';

export default {
  name: 'MapboxDevices',
  props: {
    /** @type {import('vue').Prop<mapboxgl.Map>} */
    map: {
      type: Object,
      required: true,
    },
    /** @type {import('vue').Prop<Array<MapDevice>>} */
    devices: {
      type: Array,
      required: true,
    },

    gtfsId: {
      type: String,
      required: true,
    },

    showLabels: {
      type: Boolean,
      default: false,
    },

    ts: {
      type: Number,
      default: null,
    },
    displayTooltip: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['mouseenter', 'click', 'mouseleave'],

  data: () => ({
    /** @type {?string} */
    hoveredId: null,

    loaded: false,

    timer: {
      _id: null,
      ts: Date.now() / 1000,
    },
  }),

  computed: {
    /** @return {number} */
    currentTs() {
      if (this.ts != null) return this.ts;
      return this.timer.ts;
    },

    /** @return {{[deviceId: string]: DeviceFiltered}} */
    devicesFiltered() {
      if (!this.loaded) return {};
      return this.devices.reduce((acc, elem) => {
        acc[elem.id] = {
          ...elem.device,
          additionalDatas: elem.additionalDatas,
        };
        return acc;
      }, /** @type {{[deviceId: string]: import('@/store/devices').Device}} */ ({}));
    },

    /** @return {Array<GeoJSON.Feature<GeoJSON.Point>>} */
    devicesSource() {
      return Object.values(this.devicesFiltered).map(device => {
        const deviceTs = this.ts == null ? (device.ts_system ?? device.ts) : device.ts;
        const isDeviceOnline = deviceTs > this.currentTs - this.$store.getters.group.delay_device_online;
        return /** @type {GeoJSON.Feature<GeoJSON.Point>} */ ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: device.latlng ? [device.latlng[1], device.latlng[0]] : [0, 0],
          },
          properties: {
            bearing: device.bearing,
            description: this.generateDeviceDescription(device),
            color: this.getDeviceIconColor(device),
            routeColor: isDeviceOnline ? this.getDeviceRouteColor(device) : '#FFFFFF',
            highlight: this.highlightDevices[device.device_id],
            id: device.device_id,
            label: this.getLabel(device),
            opacity: isDeviceOnline ? 1 : 0.5,
          },
        });
      });
    },

    /** @return {{[stopId: string]: boolean}} */
    highlightDevices() {
      return this.devices.reduce((acc, elem) => {
        if (elem.highlight) {
          acc[elem.id] = true;
        }

        return acc;
      }, /** @type {{[stopId: string]: boolean}} */ ({}));
    },

    /** @return {{[routeId: string]: import('@/store/gtfs').Route}} */
    routes() {
      return this.$store.getters['gtfs/getCachedGtfsTable'](this.gtfsId, 'routes');
    },

    /** @return {{[routeId: string]: import('@/store/gtfs').Trip}} */
    trips() {
      return this.$store.getters['gtfs/getCachedGtfsTable'](this.gtfsId, 'trips');
    },
    /** @return {string} */
    groupId() {
      return this.$store.getters.group._id;
    },
  },
  watch: {
    devicesSource: {
      deep: true,
      handler() {
        MapboxHelper.updateSource(this.map, DEVICES_SOURCE_ID, this.devicesSource);
      },
    },
    showLabels: {
      immediate: false,
      handler() {
        this.map.setFilter(DEVICES_LABEL_LAYER_ID, [
          'any',
          this.showLabels,
          ['==', ['get', 'id'], this.hoveredId],
        ]);
      },
    },
  },
  created() {
    this.map.once('idle', () => {
      this.loaded = true;
    });
    MapboxHelper.addImage(this.map, BUS_ICON, 'busIcon');
    MapboxHelper.addImage(this.map, BEARING_ICON, 'bearing');

    this.timer._id = setInterval(() => {
      this.timer.ts = Date.now() / 1000;
    }, 1000);
    this.initSourceAndLayer();
  },

  unmounted() {
    clearInterval(this.timer._id);

    MapboxHelper.cleanLayersAndSources(
      this.map,
      [
        DEVICES_LABEL_LAYER_ID,
        DEVICES_HIGHLIGHT_COLOR_LAYER_ID,
        DEVICES_HIGHLIGHT_ROUTE_COLOR_LAYER_ID,
        DEVICES_HIGHLIGHT_ICON_LAYER_ID,
        DEVICES_HIGHLIGHT_BEARING_LAYER_ID,
        DEVICES_COLOR_LAYER_ID,
        DEVICES_ROUTE_COLOR_LAYER_ID,
        DEVICES_ICON_LAYER_ID,
        DEVICES_BEARING_LAYER_ID,
      ],
      [DEVICES_SOURCE_ID],
    );

    MapboxHelper.cleanImage(this.map, 'busIcon');
    MapboxHelper.cleanImage(this.map, 'bearing');
  },

  methods: {
    initSourceAndLayer() {
      MapboxHelper.createEmptySource(this.map, DEVICES_SOURCE_ID);

      this.addDevicesBearingLayer();
      this.addDevicesRouteColorLayer();
      this.addDevicesIconLayer();
      this.addDevicesColorLayer();
      this.addDevicesHighlightBearingLayer();
      this.addDevicesHighlightRouteColorLayer();
      this.addDevicesHighlightIconLayer();
      this.addDevicesHighlightColorLayer();
      this.addDevicesLabelLayer();
    },
    addDevicesLabelLayer() {
      MapboxHelper.addLayer(
        this.map,
        /** @type {mapboxgl.Layer} */ ({
          id: DEVICES_LABEL_LAYER_ID,
          type: 'symbol',
          source: DEVICES_SOURCE_ID,
          filter: ['any', this.showLabels, ['==', ['get', 'id'], this.hoveredId]],

          layout: {
            'text-allow-overlap': false,
            'text-anchor': 'top',
            'text-field': ['get', 'label'],
            'text-justify': 'center',
            'text-offset': [0, -2.6],
            'text-font': ['Poppins Medium'],
            'text-max-width': 100,
          },

          paint: {
            'text-halo-color': '#FBFBFB', // $text-light
            'text-color': '#333333', // $text-dark
            'text-halo-width': 3,
          },
        }),
      );
    },
    addDevicesBearingLayer() {
      MapboxHelper.addLayer(
        this.map,
        /** @type {mapboxgl.Layer} */ (
          deepmerge(LAYER_BEARING, {
            id: DEVICES_BEARING_LAYER_ID,
            source: DEVICES_SOURCE_ID,
            filter: ['!=', ['get', 'highlight'], true],
          })
        ),
      );
    },

    addDevicesRouteColorLayer() {
      MapboxHelper.addLayer(
        this.map,
        /** @type {mapboxgl.Layer} */ (
          deepmerge(LAYER_ROUTE_COLOR, {
            id: DEVICES_ROUTE_COLOR_LAYER_ID,
            source: DEVICES_SOURCE_ID,
            filter: ['all', ['!=', ['get', 'highlight'], true], ['!=', ['get', 'routeColor'], null]],
          })
        ),
      );
    },
    addDevicesColorLayer() {
      MapboxHelper.addLayer(
        this.map,
        /** @type {mapboxgl.Layer} */ (
          deepmerge(LAYER_COLOR, {
            id: DEVICES_COLOR_LAYER_ID,
            source: DEVICES_SOURCE_ID,
            filter: ['all', ['!=', ['get', 'highlight'], true], ['!=', ['get', 'color'], null]],
          })
        ),
      );
    },
    addDevicesHighlightBearingLayer() {
      MapboxHelper.addLayer(
        this.map,
        /** @type {mapboxgl.Layer} */ (
          deepmerge(LAYER_BEARING, {
            id: DEVICES_HIGHLIGHT_BEARING_LAYER_ID,
            source: DEVICES_SOURCE_ID,
            filter: ['==', ['get', 'highlight'], true],
            layout: {
              'icon-size': 0.85,
            },
          })
        ),
      );
    },
    addDevicesHighlightColorLayer() {
      MapboxHelper.addLayer(
        this.map,
        /** @type {mapboxgl.Layer} */ (
          deepmerge(
            LAYER_COLOR,
            {
              id: DEVICES_HIGHLIGHT_COLOR_LAYER_ID,
              source: DEVICES_SOURCE_ID,
              filter: ['all', ['==', ['get', 'highlight'], true], ['!=', ['get', 'color'], null]],

              paint: {
                'circle-radius': 8,
                'circle-translate': [15, -15],
              },
            },
            { arrayMerge: (_, newArr) => newArr },
          )
        ),
      );
    },
    addDevicesHighlightRouteColorLayer() {
      MapboxHelper.addLayer(
        this.map,
        /** @type {mapboxgl.Layer} */ (
          deepmerge(
            LAYER_ROUTE_COLOR,
            {
              id: DEVICES_HIGHLIGHT_ROUTE_COLOR_LAYER_ID,
              source: DEVICES_SOURCE_ID,
              filter: ['all', ['==', ['get', 'highlight'], true], ['!=', ['get', 'routeColor'], null]],

              paint: {
                'circle-radius': 18,
              },
            },
            { arrayMerge: (_, newArr) => newArr },
          )
        ),
      );
    },
    addDevicesHighlightIconLayer() {
      MapboxHelper.addLayer(
        this.map,
        /** @type {mapboxgl.Layer} */ (
          deepmerge(LAYER_ICON, {
            id: DEVICES_HIGHLIGHT_ICON_LAYER_ID,
            source: DEVICES_SOURCE_ID,
            filter: ['==', ['get', 'highlight'], true],

            layout: {
              'icon-size': 0.4,
            },
          })
        ),
      );
      this.addLayerActions(DEVICES_HIGHLIGHT_ICON_LAYER_ID);
    },
    addDevicesIconLayer() {
      MapboxHelper.addLayer(
        this.map,
        /** @type {mapboxgl.Layer} */ (
          deepmerge(LAYER_ICON, {
            id: DEVICES_ICON_LAYER_ID,
            source: DEVICES_SOURCE_ID,
            filter: ['!=', ['get', 'highlight'], true],
          })
        ),
      );
      this.addLayerActions(DEVICES_ICON_LAYER_ID);
    },

    addLayerActions(layerName) {
      this.map.on('mouseenter', layerName, e => {
        if (e.features && e.features.length > 0) {
          this.$emit('mouseenter', e);
        }
      });
      this.map.on('mouseleave', layerName, e => {
        this.onMouseLeave(e);
      });
      this.map.on('mousemove', layerName, e => {
        if (e.features && e.features.length > 0) {
          this.onMouseMove(e);
        }
      });
      this.map.on('click', layerName, e => {
        if (e.features && e.features.length > 0) {
          this.$emit('click', e);
          if (this.displayTooltip) {
            MapboxHelper.displayTooltipOnClick(e, this.map, 'tooltip-map');
          }
        }
      });
    },
    /** @param {import('@/store/devices').Device} device */
    getDeviceIconColor(device) {
      if (device.delay != null) {
        return `#${DEVICE_DELAY_COLOR[this.$store.getters['devices/getDelayState'](device.delay)]}`;
      }
      return null;
    },
    getDeviceRouteColor(device) {
      if (device.route_id !== null) {
        const route = this.routes[device.route_id];
        if (route && route.route_color) {
          return `#${route.route_color}`;
        }
      }
      return '#FFFFFF';
    },

    /** @param {import('@/store/devices').Device} device */
    getLabel(device) {
      let value = this.$store.state.devices.mapLabelFormat;
      if (!value) {
        value = '%th';
      }

      // %th : trip headsign
      let th = '-';
      const trip = this.trips[device.trip_id];
      if (trip) {
        th = trip.trip_headsign || '-';
      }
      value = value.replace(/%th/g, th);

      // %dn : device name
      value = value.replace(/%dn/g, device.name || '-');

      // %rsn : route short name
      let rsn = '-';
      const route = this.routes[device.route_id];
      if (route) {
        rsn = route.route_short_name || '-';
      }
      value = value.replace(/%rsn/g, rsn);

      // %tsn : trip short name
      let tsn = '-';
      if (trip) {
        tsn = trip.trip_short_name || '-';
      }
      value = value.replace(/%tsn/g, tsn);

      // %lts : last timestamp
      value = value.replace(/%lts/g, device.ts != null ? this.$d(device.ts * 1000, 'timeShort') : '-');

      // %s : speed
      value = value.replace(/%s/g, device.speed != null ? Math.floor((device.speed * 36) / 10) : '-');

      // %ll : GPS coords
      const [lat, lng] = device.latlng || [0, 0];
      // eslint-disable-next-line no-irregular-whitespace
      value = value.replace(/%ll/g, `${this.$t('lat')} : ${lat}\n${this.$t('lng')} : ${lng}`);

      // %d : delay
      const sign = device.delay >= 0 ? '+' : '-';
      const min = Math.floor(Math.abs(device.delay) / 60);
      let delay = this.$t('delayMn', [sign + min]);
      if (min === 0) delay = '';

      value = value.replace(/%d/g, delay);

      return value;
    },

    /** @param {mapboxgl.MapLayerMouseEvent} event */
    onMouseLeave(event) {
      this.$emit('mouseleave', event);
      this.hoveredId = null;
      this.setDeviceLabelFilterUpdate();
    },

    /** @param {mapboxgl.MapLayerMouseEvent} event */
    onMouseMove(event) {
      event.originalEvent.cancelBubble = true;

      if (event?.features.length > 0) {
        const deviceId = event.features[0]?.properties?.id;

        if (this.hoveredId !== deviceId) {
          this.hoveredId = deviceId;
        }
        this.setDeviceLabelFilterUpdate();
      }
    },
    setDeviceLabelFilterUpdate() {
      this.map.setFilter(DEVICES_LABEL_LAYER_ID, [
        'any',
        this.showLabels,
        ['==', ['get', 'id'], this.hoveredId],
      ]);
    },
    /**
     * Generate device tooltip HTML based on devices informations
     * @param {DeviceFiltered} device
     */
    generateDeviceDescription(device) {
      const data = device?.additionalDatas;
      if (data) {
        let description = ``;
        // Driver & vehicle
        if (data?.driver) description += `<span>${data.driver}</span>`;
        if (data?.vehicle) description += `<span>${data.vehicle}</span>`;
        // Route badge & formattedTripName
        if (data.route) {
          description += `<div class="tooltip-map__trip ${
            data?.driver || data?.vehicle ? 'tooltip-map__separation-border' : ''
          }"><span class="route-badge" style="background-color: #${
            data.route.route_color || 'FFFFFF'
          };color: #${data.route.route_text_color || '000000'}">${
            data.route.route_short_name
          }  </span><span class="tooltip-map__trip__text">${data.formattedTripName}</span></div>`;
        }
        // CTA button
        if (device?.trip_id) {
          const tooltipCtaUrl = this.generateTripDetailUrl(device);
          description += `<a class="tooltip-map__button" href="${tooltipCtaUrl}">${this.$t(
            'detail',
          )} <i class="fas fa-arrow-right ui-btn__redirect-icon" /></a>`;
        }
        if (description.length > 0) return description;
      }
      return null;
    },
    /**
     * generate trip detail URL based on device since we can't use vue rooter in generated html
     * @param {DeviceFiltered} device
     */
    generateTripDetailUrl(device) {
      const tripStartDate = device?.trip?.start_date
        ? getISODate(dateGtfsFormatToObj(device?.trip?.start_date))
        : null;
      let tooltipCtaUrl = `${AppUrl}/#/${this.groupId}/${GroupRoute.TRIP_DETAILED}/${device.trip_id}`;
      if (tripStartDate) tooltipCtaUrl += `?date=${tripStartDate}`;
      return tooltipCtaUrl;
    },
  },
};

/**
 * @typedef {Object} MapDevice
 * @property {import('@/store/devices').Device} device
 * @property {string} id
 * @property {boolean} highlight
 */

/**
 * @typedef {Object} DeviceFiltered
 * @extends Device
 * @property {AdditionnalDatas} additionalDatas
 */

/**
 * @typedef {Object} AdditionnalDatas
 * @property {import('@/store/gtfs').Route} route
 * @property {string} driver
 * @property {string} vehicle
 * @property {string} formattedTripName
 */
</script>

<i18n locale="fr">
{
  "delayMn": "{0} min",
  "lat": "Latitude",
  "lng": "Longitude"
}
</i18n>

<i18n locale="en">
{
  "delayMn": "{0} min",
  "lat": "Latitude",
  "lng": "Longitude"
}
</i18n>

<i18n locale="cz">
{
  "lat": "Šířka",
  "lng": "Délka"
}
</i18n>

<i18n locale="de">
{
  "lat": "Breitengrad",
  "lng": "Längengrad"
}
</i18n>

<i18n locale="es">
{
  "lat": "Latitud",
  "lng": "Longitud"
}
</i18n>

<i18n locale="it">
{
  "lat": "Latitudine",
  "lng": "Longitudine"
}
</i18n>

<i18n locale="pl">
{
  "lat": "Szer. geogr.",
  "lng": "Dł. geogr."
}
</i18n>

<style lang="scss">
.tooltip-map {
  &__trip {
    display: flex;
    flex-direction: row;
    gap: 10px;

    &__text {
      line-height: 26px;
    }

    .route-badge {
      height: 26px;
    }
  }
}
</style>
