<script setup lang="ts">
import type { Trip } from '@/@types/gtfs';
import {
  HighlightType,
  SHAPES_ARROW_LAYER_ID,
  SHAPES_BG_LAYER_ID,
  SHAPES_EDITED_BG_LAYER_ID,
  SHAPES_EDITED_FG_LAYER_ID,
  SHAPES_FG_LAYER_ID,
  SHAPES_HIGHLIGHT_BG_LAYER_ID,
  SHAPES_HIGHLIGHT_FG_LAYER_ID,
  type MapTrip,
  type ShapeData,
} from '@/@types/mapbox';
import { MapboxHelper } from '@/components/map/mapboxHelper';
import { useTripUpdates } from '@/store-pinia/trip-updates';
import type { Route, Shape } from '@/store/gtfs';
import cloneDeep from 'clone-deep';
import { computed, onBeforeMount, onUnmounted, ref, watch, type PropType } from 'vue';
import { useStore } from 'vuex';

const SHAPE_ARROW_ICON = new URL(`../../assets/img/shape_arrow.png`, import.meta.url).href;

const SHAPE_SOURCE_ID = 'shapesSource';
const SHAPE_EDITED_SOURCE_ID = 'shapeEditedSource';

const store = useStore();

const props = defineProps({
  map: {
    type: Object as PropType<mapboxgl.Map>,
    required: true,
  },
  gtfsId: {
    type: String,
    required: true,
  },
  trips: {
    type: Array as PropType<Array<MapTrip>>,
    required: true,
  },
  tripUpdateMode: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['click', 'mouseenter', 'mouseleave', 'isLoaded']);

const tripUpStore = props.tripUpdateMode ? useTripUpdates() : null;

const isLoaded = ref<boolean>(false);

const highlightShapes = computed<{ [id: string]: HighlightType }>(() => {
  return props.trips.reduce((acc: { [id: string]: HighlightType }, elem) => {
    const trip = tripsGtfs.value[elem.id];
    if (!trip) return acc;

    const id = JSON.stringify({
      shapeId: trip.shape_id,
      routeId: trip.route_id,
    });

    acc[id] = Math.max(elem.highlight, acc[id] || HighlightType.NONE);

    return acc;
  }, {});
});

const hasEditedShape = computed<boolean>(() => (tripUpStore && tripUpStore.hasEditedShape) || false);
const inShapeEdition = computed<boolean>(() => (tripUpStore && tripUpStore.inShapeEdition) || false);

const tripUpShapeEdited = computed<Array<GeoJSON.Feature<GeoJSON.LineString>> | null>(() => {
  if (tripUpStore && tripUpStore.editedShape) {
    const defaultShape = cloneDeep(Object.values(shapesFiltered.value)?.[0]);
    if (defaultShape) {
      defaultShape.geometry.coordinates = tripUpStore.editedShape;
      return [
        {
          type: 'Feature',
          geometry: defaultShape.geometry,
          properties: {
            color: `#${defaultShape.color}`,
            highlight: highlightShapes.value[defaultShape.id],
            id: defaultShape.id,
          },
        },
      ];
    }
  }
  return null;
});

const shapesFiltered = computed<{ [id: string]: ShapeData }>(() => {
  if (!isLoaded.value) return {};

  const shapesGtfs: { [shapeId: string]: Shape } = store.getters['gtfs/getCachedGtfsTable'](
    props.gtfsId,
    'shapes',
  );
  const routesGtfs: { [routeId: string]: Route } = store.getters['gtfs/getCachedGtfsTable'](
    props.gtfsId,
    'routes',
  );

  return props.trips.reduce((acc: { [id: string]: ShapeData }, elem) => {
    const trip = tripsGtfs.value[elem.id];
    if (!trip) return acc;

    const route = routesGtfs[trip.route_id];
    const shape = shapesGtfs[trip.shape_id];
    if (!route || !shape) return acc;

    const id = JSON.stringify({
      shapeId: trip.shape_id,
      routeId: trip.route_id,
    });

    if (!acc[id]) {
      acc[id] = {
        color: route.route_color || 'ffffff',
        geometry: shape.geometry,
        id,
      };
    }

    return acc;
  }, {});
});

const shapesSource = computed<Array<GeoJSON.Feature<GeoJSON.LineString>>>(() => {
  return Object.values(shapesFiltered.value).map(shape => ({
    type: 'Feature',
    geometry: shape.geometry,
    properties: {
      color: `#${shape.color}`,
      highlight: highlightShapes.value[shape.id],
      id: shape.id,
    },
  }));
});

const tripsGtfs = computed<{ [id: string]: Trip }>(() => {
  return store.getters['gtfs/getCachedGtfsTable'](props.gtfsId, 'trips');
});

watch(
  () => props.gtfsId,
  () => {
    store.dispatch('gtfs/getRoutesMap', { gtfsId: props.gtfsId });
    store.dispatch('gtfs/getShapesMap', { gtfsId: props.gtfsId });
  },
  { immediate: true },
);

watch(
  () => shapesSource.value,
  () => {
    MapboxHelper.updateSource(props.map, SHAPE_SOURCE_ID, shapesSource.value);
    if (props.map.getStyle().layers?.find(layer => layer.id === SHAPES_EDITED_FG_LAYER_ID)) {
      props.map.moveLayer(SHAPES_FG_LAYER_ID, SHAPES_EDITED_FG_LAYER_ID);
    }
  },
  { deep: true },
);

watch(
  () => tripUpShapeEdited.value,
  () => {
    if (tripUpShapeEdited.value)
      MapboxHelper.updateSource(props.map, SHAPE_EDITED_SOURCE_ID, tripUpShapeEdited.value);
  },
  { deep: true },
);

watch(
  () => [hasEditedShape.value, inShapeEdition.value],
  () => {
    MapboxHelper.cleanLayersAndSources(
      props.map,
      [SHAPES_BG_LAYER_ID, SHAPES_FG_LAYER_ID, SHAPES_EDITED_BG_LAYER_ID, SHAPES_EDITED_FG_LAYER_ID],
      [],
    );
    if (!hasEditedShape.value && !inShapeEdition.value) {
      addShapesBgLayer();
    }
    if (hasEditedShape.value) {
      MapboxHelper.createEmptySource(props.map, SHAPE_EDITED_SOURCE_ID);
      addShapesEditedBgLayer();
      addShapesEditedFgLayer();
    }
    addShapesFgLayer();
  },
);

onBeforeMount(() => {
  props.map.once('idle', () => {
    isLoaded.value = true;
  });
  MapboxHelper.addImage(props.map, SHAPE_ARROW_ICON, 'shape_arrow');
  initSourceAndLayer();
});

onUnmounted(() => {
  MapboxHelper.cleanLayersAndSources(
    props.map,
    [
      SHAPES_ARROW_LAYER_ID,
      SHAPES_HIGHLIGHT_FG_LAYER_ID,
      SHAPES_HIGHLIGHT_BG_LAYER_ID,
      SHAPES_FG_LAYER_ID,
      SHAPES_BG_LAYER_ID,
      SHAPES_EDITED_FG_LAYER_ID,
      SHAPES_EDITED_BG_LAYER_ID,
    ],
    [SHAPE_SOURCE_ID, SHAPE_EDITED_SOURCE_ID],
  );
  MapboxHelper.cleanImage(props.map, 'shape_arrow');
});

function initSourceAndLayer() {
  MapboxHelper.createEmptySource(props.map, SHAPE_SOURCE_ID);

  if (!hasEditedShape.value && !inShapeEdition.value) {
    addShapesBgLayer();
  }
  addShapesFgLayer();

  if (hasEditedShape.value) {
    MapboxHelper.createEmptySource(props.map, SHAPE_EDITED_SOURCE_ID);
    addShapesEditedBgLayer();
    addShapesEditedFgLayer();
  }

  addShapesHighlightBgLayer();
  addShapesHighlightFgLayer();
  addShapesArrowLayer();
  emit('isLoaded', true);
}

function addShapesArrowLayer() {
  MapboxHelper.addLayer(props.map, {
    id: SHAPES_ARROW_LAYER_ID,
    type: 'symbol',
    source: SHAPE_SOURCE_ID,
    filter: ['==', ['get', 'highlight'], HighlightType.MORE],
    layout: {
      'icon-image': 'shape_arrow',
      'icon-size': 0.25,
      'symbol-spacing': 50,
      'symbol-placement': 'line',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
    },
  });
}

function addShapesHighlightFgLayer() {
  MapboxHelper.addLayer(props.map, {
    id: SHAPES_HIGHLIGHT_FG_LAYER_ID,
    type: 'line',
    source: SHAPE_SOURCE_ID,
    filter: ['==', ['get', 'highlight'], HighlightType.MORE],
    paint: {
      'line-width': 3,
      'line-color': ['get', 'color'],
    },
  });
}

function addShapesHighlightBgLayer() {
  MapboxHelper.addLayer(props.map, {
    id: SHAPES_HIGHLIGHT_BG_LAYER_ID,
    type: 'line',
    source: SHAPE_SOURCE_ID,
    filter: ['==', ['get', 'highlight'], HighlightType.MORE],
    paint: {
      'line-width': 5,
      'line-color': '#000000',
    },
  });
}

function addShapesFgLayer() {
  MapboxHelper.addLayer(props.map, {
    id: SHAPES_FG_LAYER_ID,
    type: 'line',
    source: SHAPE_SOURCE_ID,
    filter: ['!=', ['get', 'highlight'], HighlightType.MORE],
    paint: {
      'line-width': ['case', ['==', ['get', 'highlight'], HighlightType.LESS], 2, 3],
      'line-color': hasEditedShape.value || inShapeEdition.value ? '#000' : ['get', 'color'],
      'line-opacity':
        hasEditedShape.value || inShapeEdition.value
          ? 0.5
          : ['case', ['==', ['get', 'highlight'], HighlightType.LESS], 0.3, 1],
      'line-dasharray': hasEditedShape.value || inShapeEdition.value ? [0, 2, 2] : [],
    },
  });
  props.map.on('click', SHAPES_FG_LAYER_ID, e => {
    if (e.features && e.features.length > 0) {
      emit('click', e);
    }
  });
  props.map.on('mouseenter', SHAPES_FG_LAYER_ID, e => {
    if (e.features && e.features.length > 0) {
      emit('mouseenter', e);
    }
  });
  props.map.on('mouseleave', SHAPES_FG_LAYER_ID, e => {
    emit('mouseleave', e);
  });
}

function addShapesEditedFgLayer() {
  MapboxHelper.addLayer(props.map, {
    id: SHAPES_EDITED_FG_LAYER_ID,
    type: 'line',
    source: SHAPE_EDITED_SOURCE_ID,
    filter: ['!=', ['get', 'highlight'], HighlightType.MORE],
    paint: {
      'line-width': ['case', ['==', ['get', 'highlight'], HighlightType.LESS], 2, 3],
      'line-color': ['get', 'color'],
      'line-opacity': ['case', ['==', ['get', 'highlight'], HighlightType.LESS], 0.3, 1],
    },
  });
}

function addShapesBgLayer() {
  MapboxHelper.addLayer(props.map, {
    id: SHAPES_BG_LAYER_ID,
    type: 'line',
    source: SHAPE_SOURCE_ID,
    filter: ['!=', ['get', 'highlight'], HighlightType.MORE],
    paint: {
      'line-width': ['match', ['get', 'highlight'], HighlightType.LESS, 0, 4],
      'line-color': '#000000',
    },
  });
}

function addShapesEditedBgLayer() {
  MapboxHelper.addLayer(props.map, {
    id: SHAPES_EDITED_BG_LAYER_ID,
    type: 'line',
    source: SHAPE_EDITED_SOURCE_ID,
    filter: ['!=', ['get', 'highlight'], HighlightType.MORE],
    paint: {
      'line-width': ['match', ['get', 'highlight'], HighlightType.LESS, 0, 4],
      'line-color': '#000000',
    },
  });
}
</script>

<template>
  <div class="mapbox-trips"></div>
</template>
