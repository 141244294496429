import i18n from '@/i18n';
import { UpdateType } from '@/store-pinia/trip-updates';

const { t } = i18n.global;

/** @enum {string} */
export const ExportColumns = {
  EVENT: 'event',
  REAL_TIME: 'realTime',
  THEORETICAL_TIME: 'theoreticalTime',
  DELAY: 'delay',
};

/** @enum {string} */
export const FeedEventType = {
  ARRIVAL: 'arrival',
  DEPARTURE: 'departure',
  INBOX: 'inbox',
  MESSAGE_SENT: 'message_sent',
};

// Trip updates must always appear in the same order on the Trip detailed page
/** @type {Array<UpdateType>} */
export const TRIP_UPDATE_ORDER = [
  UpdateType.COMMENT,
  UpdateType.STOP_INFO,
  UpdateType.TRIP_CANCELED,
  UpdateType.DELAY,
  UpdateType.DO_NOT_SERVE,
  UpdateType.DISPLACED_STOPS,
  UpdateType.TEMPORARY_STOPS,
  UpdateType.SPECIFIC_DELAYS,
  UpdateType.SHAPE,
];

export const formatDelay = delay => {
  if (delay != null) {
    let sign = '';
    if (delay > 0) {
      sign = '+';
    }
    return `${sign + Math.floor(delay / 60)} min`;
  }
  return null;
};

export const getRowContent = row => {
  switch (row.type) {
    case FeedEventType.ARRIVAL:
      return `${t('eventFeed.arrivalAt')}${row.stopName}`;
    case FeedEventType.DEPARTURE:
      return `${t('eventFeed.departureFrom')}${row.stopName}`;
    case FeedEventType.INBOX:
      return `${t('eventFeed.inbox')}${row.content}`;
    case FeedEventType.MESSAGE_SENT:
    default:
      return '';
  }
};
