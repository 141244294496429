export enum GroupRoute {
  DUTY_LIST = 'duty-list',
  PASSENGERS_MESSAGE = 'passengers-message',
  DASHBOARD = 'dashboard',
  DEVIATION_LIST = 'deviations',
  DEVICE_LIST = 'device-list',
  DEVICE_DETAILLED = 'device-detailled',
  DRIVER_LIST = 'driver-list',
  GROUP = 'group',
  GTFS_RT = 'gtfs-rt',
  INTEGRATIONS = 'integrations',
  LIVE_MAP = 'live-map',
  MESSAGE_LIST = 'message-list',
  MESSAGE_INBOX = 'inbox-messages',
  MESSAGE_SENT = 'sent-messages',
  NOT_FOUND = 'not-found',
  PASSENGERS_APP = 'passengers-app',
  PASSENGERS_SCREEN = 'passengers-screen',
  PLANNING = 'planning',
  PROFILE = 'profile',
  PROFILE_GENERAL = 'profile-general',
  PROFILE_PASSWORD = 'profile-password',
  REPORTS = 'reporting',
  REPORTING_TRIP_KM = 'trip-km',
  REPORTING_TRAVEL_TIME = 'travel-time',
  REPORTING_TRIP_TRACKING = 'trip-tracking',
  REPORTING_PASSENGER_COUNTS = 'passenger-counts',
  REPORTING_CONNECTED_DEVICES = 'connected-devices',
  REPORTING_PASSENGERS_APP = 'passengers-app-sessions',
  REPORTING_OLD_PUNCTUALITY = 'old-punctuality',
  REPORTING_PUNCTUALITY = 'punctuality',
  RESOURCES = 'resources',
  TRANSPORT_PLAN_LIST = 'transport-plan-list',
  TRIP_MODIFICATION = 'trip-modification',
  SETTINGS = 'settings',
  STOP_LIST = 'stop-list',
  STOP_DETAILED = 'stop-detailed',
  TRIP_LIST = 'trip-list',
  TRIP_DETAILED = 'trip-detailed',
  URGENCIES = 'urgencies',
  USER_LIST = 'user-list',
  VEHICLE_LIST = 'vehicle-list',
}

export enum AdminRoute {
  ADMIN = 'admin',
}

export enum AuthRoute {
  LOGIN = 'login',
  LOGIN_FORM = 'login_form',
  PASSWORD_CHANGE = 'password-change',
  PASSWORD_RESET = 'password-reset',
}

export enum UnauthorizedRoute {
  INTERNAL = 'unauthorizedInternal',
  EXTERNAL = 'unauthorizedExternal',
}

export enum HomeRoute {
  ROOT = 'root',
  MAINTENANCE = 'maintenance',
}
