<template>
  <Modal modal-class="modal-live-map-options" @close="$emit('close')">
    <template #title>
      {{ $t('infosEdit') }}
    </template>

    <template #body>
      <div class="modal-live-map-options__subtitle">
        {{ $t('infosFormat') }}
      </div>

      <p>{{ $t('infosFormatPar') }}</p>

      <div class="form-group">
        <textarea v-model="localLabelFormat" class="form-group__input" placeholder="%th" />
      </div>
      <ToggleSwitch
        id="display-labels-name"
        :checked="localValueDisplayVehicleLabel"
        @change="checked => (localValueDisplayVehicleLabel = checked)"
      />
      <span class="switch-label">{{ $t('keepVehicleDisplay') }}</span>
    </template>

    <template #cta>
      <Btn type="primary" @click="onSubmit">
        {{ $t('submit') }}
      </Btn>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';
import ToggleSwitch from '@/components/ui/ToggleSwitch.vue';

export default {
  name: 'ModalMapOptions',

  components: {
    Btn,
    Modal,
    ToggleSwitch,
  },
  props: {
    keepDisplayVehicleLabel: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['close', 'update:keepDisplayVehicleLabel'],

  data: () => ({
    /** @type {string} */
    localLabelFormat: null,
    localValueDisplayVehicleLabel: null,
  }),

  computed: {
    /** @type {import('vue').ComputedOptions<string>} */
    labelFormat: {
      get() {
        return this.$store.state.devices.mapLabelFormat;
      },

      set(val) {
        this.$store.commit('devices/setMapLabelFormat', val);
        localStorage.setItem('settings.op.mapDeviceLabelFormat', val);
      },
    },
  },

  watch: {
    labelFormat: {
      immediate: true,
      handler() {
        this.localLabelFormat = this.labelFormat;
      },
    },
  },
  created() {
    this.localValueDisplayVehicleLabel = this.keepDisplayVehicleLabel;
  },

  methods: {
    onSubmit() {
      if (this.labelFormat !== this.localLabelFormat) {
        this.labelFormat = this.localLabelFormat;
      }
      this.$emit('update:keepDisplayVehicleLabel', this.localValueDisplayVehicleLabel);
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.modal-live-map-options {
  &__subtitle {
    font-weight: $font-weight-semi-bold;
  }

  .switch-label {
    margin-left: 10px;
  }
}
</style>

<i18n locale="fr">
{
  "infosEdit": "Modifier les informations affichées",
  "infosFormat": "Format des informations affichées",
  "infosFormatPar": "Définit le format des informations à afficher sur les appareils (%th : girouette - %dn : nom du véhicule - %tsn : nom de course court - %rsn : ligne - %lts : date et heure de la dernière communication - %s : vitesse - %ll : coordonnées GPS - %d : avance/retard)",
  "keepVehicleDisplay": "Garder l’affichage de l’étiquette sur la carte",
}
</i18n>

<i18n locale="en">
{
  "infosEdit": "Change the information displayed",
  "infosFormat": "Format of the information displayed",
  "infosFormatPar": "Define the format of the information to be displayed on the device (%th: electronic sign - %dn : vehicle name - %tsn : trip short name - %rsn : line - %lts : last communication date and hour - %s : speed - %II : GPS coordinates - %d : delay)",
  "keepVehicleDisplay": "Keep the label displayed on the map",
}
</i18n>

<i18n locale="cz">
{
  "infosFormatPar": "Nastavte formát, ve kterém se budou informace v zařízení zobrazovat (%th: elektronická cedule - %dn: název vozidla - %rsn: linka -%lts: datum a čas poslední komunikace - %s: rychlost -%ll: GPS souřadnice)",
  "infosEdit": "Změnit zobrazovanou informaci",
  "infosFormat": "Formát zobrazované informace",
}
</i18n>

<i18n locale="de">
{
  "infosFormatPar": "Das Format für die auf dem Gerät angezeigten Informationen festlegen (%th: Digitale Anzeigetafel - %dn: Fahrzeugname - %rsn: Strecke - %lts: Datum und Uhrzeit der letzten Kommunikation - %s: Geschwindigkeit - %II: GPS-Koordinaten)",
  "infosEdit": "Angezeigte Informationen ändern",
  "infosFormat": "Format der angezeigten Informationen",
}
</i18n>

<i18n locale="es">
{
  "infosFormatPar": "Define el formato de la información que va a mostrarse en el dispositivo (%th: indicador electrónico - %dn : nombre de vehículo - %rsn : línea - %lts : última fecha y hora de comunicación - %s : velocidad - %ll : coordenadas GPS)",
  "infosEdit": "Cambiar la información mostrada",
  "infosFormat": "Formato de la información mostrada",
}
</i18n>

<i18n locale="it">
{
  "infosFormatPar": "Definisci il formato delle informazioni da visualizzare sul dispositivo (%th: firma elettronica - %dn: nome del veicolo - %rsn: linea - %lts: data e ora dell'ultima comunicazione - %s: velocità - %II: coordinate GPS)",
  "infosEdit": "Modifica le informazioni visualizzate",
  "infosFormat": "Formato delle informazioni visualizzate",
}
</i18n>

<i18n locale="pl">
{
  "infosFormatPar": "Zdefiniuj format informacji do wyświetlenia na urządzeniu (%th: znak elektroniczny - %dn: nazwa pojazdu - %rsn: linia - %lts: ostatnia data i godzina komunikacji - %s: prędkość - %ll: współrzędne GPS)",
  "infosEdit": "Zmień wyświetlaną informację",
  "infosFormat": "Format wyświetlanej informacji",
}
</i18n>
