import { UnauthorizedRoute, HomeRoute, AuthRoute, AdminRoute, GroupRoute } from '@/libs/routing';

export enum Role {
  SUPERUSER = 'superuser',
  ADMIN = 'admin',
  EXTERNAL_READER = 'external_reader',
  OPERATION_MANAGER = 'operator',
  READER = 'reader',
  DRIVER = 'driver',
  TECHNICAL = 'technical',
}

export enum Permission {
  ACCESS_ADMIN = 'ACCESS_ADMIN',
  ACCESS_OP = 'ACCESS_OP',
  ADMIN_USERS = 'ADMIN_USERS',
  CREATE_MESSAGES = 'CREATE_MESSAGES',
  DEFAULT = 'DEFAULT',
  EDIT_GROUP_PARAMETERS = 'EDIT_GROUP_PARAMETERS',
  EDIT_PASSENGERS_MESSAGES = 'EDIT_PASSENGERS_MESSAGES',
  EDIT_STOP_INFO = 'EDIT_STOP_INFO',
  EDIT_TRIP_UPDATES = 'EDIT_TRIP_UPDATES',
  EXPORT_DRIVERS = 'EXPORT_DRIVERS',
  EXPORT_PASSENGERS_MESSAGES = 'EXPORT_PASSENGERS_MESSAGES',
  EXPORT_TRIP = 'EXPORT_TRIP',
  EXPORT_TRIP_LIST = 'EXPORT_TRIP_LIST',
  EXPORT_VEHICLES = 'EXPORT_VEHICLES',
  HANDLE_URGENCIES = 'HANDLE_URGENCIES',
  IMPORT_DRIVERS = 'IMPORT_DRIVERS',
  IMPORT_VEHICLES = 'IMPORT_VEHICLES',
  MODIFY_TRIP = 'MODIFY_TRIP',
  RESEARCH = 'RESEARCH',
  VIEW_DASHBOARD = 'VIEW_DASHBOARD',
  VIEW_DUTIES = 'VIEW_DUTIES',
  VIEW_DEVIATION_LIST = 'VIEW_DEVIATION_LIST',
  VIEW_GROUP_FEATURES = 'VIEW_GROUP_FEATURES',
  VIEW_PLANNING = 'VIEW_PLANNING',
  VIEW_GTFS_RT = 'VIEW_GTFS_RT',
  VIEW_INTEGRATIONS = 'VIEW_INTEGRATIONS',
  VIEW_MESSAGES = 'VIEW_MESSAGES',
  VIEW_OVER_A_WEEK_HISTORY = 'VIEW_OVER_A_WEEK_HISTORY',
  VIEW_PASSENGERS_APP = 'VIEW_PASSENGERS_APP',
  VIEW_REPORT = 'VIEW_REPORT',
  VIEW_SETTINGS_BUTTON = 'VIEW_SETTINGS_BUTTON',
  VIEW_STOP_INFO = 'VIEW_STOP_INFO',
  VIEW_TEAM_COLUMN = 'VIEW_TEAM_COLUMN',
  VIEW_TRIP_COMMENTS = 'VIEW_TRIP_COMMENTS',
  VIEW_TRIP_DEVICES = 'VIEW_TRIP_DEVICES',
  VIEW_TRIP_DRIVERS = 'VIEW_TRIP_DRIVERS',
  VIEW_TRIP_KM = 'VIEW_TRIP_KM',
  VIEW_TRIP_VEHICLES = 'VIEW_TRIP_VEHICLES',
  VIEW_TRIP_VIEW = 'VIEW_TRIP_VIEW',
  VIEW_URGENCIES = 'VIEW_URGENCIES',
}

export const permissionsByRole = {
  [Role.SUPERUSER]: new Set(Object.values(Permission)),
  [Role.ADMIN]: new Set([
    Permission.ACCESS_OP,
    Permission.ADMIN_USERS,
    Permission.CREATE_MESSAGES,
    Permission.EDIT_GROUP_PARAMETERS,
    Permission.EDIT_PASSENGERS_MESSAGES,
    Permission.EDIT_STOP_INFO,
    Permission.EDIT_TRIP_UPDATES,
    Permission.EXPORT_DRIVERS,
    Permission.EXPORT_PASSENGERS_MESSAGES,
    Permission.EXPORT_TRIP,
    Permission.EXPORT_TRIP_LIST,
    Permission.EXPORT_VEHICLES,
    Permission.HANDLE_URGENCIES,
    Permission.IMPORT_DRIVERS,
    Permission.IMPORT_VEHICLES,
    Permission.MODIFY_TRIP,
    Permission.RESEARCH,
    Permission.VIEW_DASHBOARD,
    Permission.VIEW_DEVIATION_LIST,
    Permission.VIEW_DUTIES,
    Permission.VIEW_GROUP_FEATURES,
    Permission.VIEW_GTFS_RT,
    Permission.VIEW_INTEGRATIONS,
    Permission.VIEW_MESSAGES,
    Permission.VIEW_OVER_A_WEEK_HISTORY,
    Permission.VIEW_PASSENGERS_APP,
    Permission.VIEW_REPORT,
    Permission.VIEW_SETTINGS_BUTTON,
    Permission.VIEW_STOP_INFO,
    Permission.VIEW_PLANNING,
    Permission.VIEW_TEAM_COLUMN,
    Permission.VIEW_TRIP_COMMENTS,
    Permission.VIEW_TRIP_DEVICES,
    Permission.VIEW_TRIP_DRIVERS,
    Permission.VIEW_TRIP_KM,
    Permission.VIEW_TRIP_VEHICLES,
    Permission.VIEW_TRIP_VIEW,
    Permission.VIEW_URGENCIES,
  ]),
  [Role.EXTERNAL_READER]: new Set([Permission.ACCESS_OP]),
  [Role.OPERATION_MANAGER]: new Set([
    Permission.ACCESS_OP,
    Permission.CREATE_MESSAGES,
    Permission.EDIT_PASSENGERS_MESSAGES,
    Permission.EDIT_STOP_INFO,
    Permission.EDIT_TRIP_UPDATES,
    Permission.EXPORT_DRIVERS,
    Permission.EXPORT_PASSENGERS_MESSAGES,
    Permission.EXPORT_TRIP,
    Permission.EXPORT_TRIP_LIST,
    Permission.EXPORT_VEHICLES,
    Permission.HANDLE_URGENCIES,
    Permission.IMPORT_DRIVERS,
    Permission.IMPORT_VEHICLES,
    Permission.MODIFY_TRIP,
    Permission.RESEARCH,
    Permission.VIEW_DASHBOARD,
    Permission.VIEW_DEVIATION_LIST,
    Permission.VIEW_DUTIES,
    Permission.VIEW_GROUP_FEATURES,
    Permission.VIEW_GTFS_RT,
    Permission.VIEW_INTEGRATIONS,
    Permission.VIEW_MESSAGES,
    Permission.VIEW_OVER_A_WEEK_HISTORY,
    Permission.VIEW_REPORT,
    Permission.VIEW_STOP_INFO,
    Permission.VIEW_PLANNING,
    Permission.VIEW_TEAM_COLUMN,
    Permission.VIEW_TRIP_COMMENTS,
    Permission.VIEW_TRIP_DEVICES,
    Permission.VIEW_TRIP_DRIVERS,
    Permission.VIEW_TRIP_KM,
    Permission.VIEW_TRIP_VEHICLES,
    Permission.VIEW_TRIP_VIEW,
    Permission.VIEW_URGENCIES,
  ]),
  [Role.READER]: new Set([
    Permission.ACCESS_OP,
    Permission.EXPORT_PASSENGERS_MESSAGES,
    Permission.EXPORT_TRIP_LIST,
    Permission.RESEARCH,
    Permission.VIEW_DASHBOARD,
    Permission.VIEW_DEVIATION_LIST,
    Permission.VIEW_GTFS_RT,
    Permission.VIEW_MESSAGES,
    Permission.VIEW_OVER_A_WEEK_HISTORY,
    Permission.VIEW_REPORT,
    Permission.VIEW_STOP_INFO,
    Permission.VIEW_TEAM_COLUMN,
    Permission.VIEW_TRIP_COMMENTS,
    Permission.VIEW_TRIP_DEVICES,
    Permission.VIEW_TRIP_DRIVERS,
    Permission.VIEW_TRIP_KM,
    Permission.VIEW_TRIP_VEHICLES,
    Permission.VIEW_TRIP_VIEW,
  ]),
  [Role.TECHNICAL]: new Set([
    Permission.ACCESS_OP,
    Permission.EXPORT_PASSENGERS_MESSAGES,
    Permission.EXPORT_TRIP_LIST,
    Permission.MODIFY_TRIP,
    Permission.RESEARCH,
    Permission.VIEW_DASHBOARD,
    Permission.VIEW_DEVIATION_LIST,
    Permission.VIEW_GTFS_RT,
    Permission.VIEW_MESSAGES,
    Permission.VIEW_OVER_A_WEEK_HISTORY,
    Permission.VIEW_REPORT,
    Permission.VIEW_STOP_INFO,
    Permission.VIEW_TEAM_COLUMN,
    Permission.VIEW_TRIP_COMMENTS,
    Permission.VIEW_TRIP_DEVICES,
    Permission.VIEW_TRIP_DRIVERS,
    Permission.VIEW_TRIP_KM,
    Permission.VIEW_TRIP_VEHICLES,
    Permission.VIEW_TRIP_VIEW,
  ]),
};

export const routesByPermission = {
  [Permission.DEFAULT]: new Set([
    UnauthorizedRoute.EXTERNAL,
    GroupRoute.NOT_FOUND,
    AuthRoute.LOGIN,
    AuthRoute.LOGIN_FORM,
    AuthRoute.PASSWORD_RESET,
    AuthRoute.PASSWORD_CHANGE,
  ]),
  [Permission.ACCESS_OP]: new Set([
    HomeRoute.ROOT,
    HomeRoute.MAINTENANCE,
    UnauthorizedRoute.INTERNAL,
    GroupRoute.PASSENGERS_MESSAGE,
    GroupRoute.PASSENGERS_SCREEN,
    GroupRoute.DEVIATION_LIST,
    GroupRoute.DEVICE_DETAILLED,
    GroupRoute.DUTY_LIST,
    GroupRoute.GROUP,
    GroupRoute.LIVE_MAP,
    GroupRoute.PROFILE,
    GroupRoute.PROFILE_GENERAL,
    GroupRoute.PROFILE_PASSWORD,
    GroupRoute.STOP_DETAILED,
    GroupRoute.TRIP_LIST,
  ]),
  [Permission.EDIT_GROUP_PARAMETERS]: new Set([GroupRoute.SETTINGS]),
  [Permission.VIEW_DASHBOARD]: new Set([GroupRoute.DASHBOARD]),
  [Permission.VIEW_GROUP_FEATURES]: new Set([
    GroupRoute.DUTY_LIST,
    GroupRoute.DEVICE_LIST,
    GroupRoute.DRIVER_LIST,
    GroupRoute.TRANSPORT_PLAN_LIST,
    GroupRoute.STOP_LIST,
    GroupRoute.USER_LIST,
    GroupRoute.VEHICLE_LIST,
  ]),
  [Permission.VIEW_PLANNING]: new Set([GroupRoute.PLANNING]),
  [Permission.VIEW_GTFS_RT]: new Set([GroupRoute.GTFS_RT]),
  [Permission.VIEW_INTEGRATIONS]: new Set([GroupRoute.INTEGRATIONS]),
  [Permission.VIEW_URGENCIES]: new Set([GroupRoute.URGENCIES]),
  [Permission.VIEW_MESSAGES]: new Set([
    GroupRoute.MESSAGE_LIST,
    GroupRoute.MESSAGE_INBOX,
    GroupRoute.MESSAGE_SENT,
  ]),
  [Permission.VIEW_PASSENGERS_APP]: new Set([GroupRoute.PASSENGERS_APP]),
  [Permission.VIEW_TRIP_VIEW]: new Set([GroupRoute.TRIP_DETAILED]),
  [Permission.VIEW_REPORT]: new Set([
    GroupRoute.REPORTS,
    GroupRoute.REPORTING_PASSENGERS_APP,
    GroupRoute.REPORTING_CONNECTED_DEVICES,
    GroupRoute.REPORTING_OLD_PUNCTUALITY,
    GroupRoute.REPORTING_PASSENGER_COUNTS,
    GroupRoute.REPORTING_PUNCTUALITY,
    GroupRoute.REPORTING_TRAVEL_TIME,
    GroupRoute.REPORTING_TRIP_KM,
    GroupRoute.REPORTING_TRIP_TRACKING,
  ]),
  [Permission.ACCESS_ADMIN]: new Set([AdminRoute.ADMIN]),
  [Permission.MODIFY_TRIP]: new Set([GroupRoute.TRIP_MODIFICATION]),
};
