import { defineAsyncComponent } from 'vue';
import { TripStatusType, UpdateType } from '@/api';
import store from '@/store';
import { Permission } from '@/auth';
import { DatetimeFormat, dateToFormattedString } from '@/libs/helpers/dates';
import { GroupRoute } from '@/libs/routing';

import {
  DataGrid,
  DataGridColumn,
  NO_DATA,
  SortOrder,
} from '@/components/Table/DataGridVuetify/models/DataGrid.models';
import { TagTypes } from '@/components/ui/Tag.vue';

const CellDelay = defineAsyncComponent(() => import('./cells/CellDelay.vue'));
const CellDevice = defineAsyncComponent(() => import('./cells/CellDevice.vue'));
const CellGtfs = defineAsyncComponent(() => import('./cells/CellGtfs.vue'));
const CellMessages = defineAsyncComponent(() => import('./cells/CellMessages.vue'));
const CellTripStatus = defineAsyncComponent(() => import('./cells/CellTripStatus.vue'));
const CellStopInfo = defineAsyncComponent(() => import('./cells/CellStopInfo.vue'));
const CellTripModification = defineAsyncComponent(() => import('./cells/CellTripModification.vue'));
const ClickableCell = defineAsyncComponent(() => import('@/components/Table/cells/ClickableCell.vue'));
const RouteBadge = defineAsyncComponent(() => import('@/components/common/RouteBadge.vue'));
const CellProgress = defineAsyncComponent(() => import('@/components/Table/cells/CellProgress.vue'));
const CommentCell = defineAsyncComponent(() => import('@/components/Table/cells/CommentCell.vue'));
const CellDropList = defineAsyncComponent(() => import('@/components/Table/cells/DropListCell.vue'));
const DateCell = defineAsyncComponent(() => import('@/components/Table/cells/DateCell.vue'));
const TeamDisplay = defineAsyncComponent(() => import('@/components/ui/SingleTeamSelector.vue'));

export const STATUSES = {
  [TripStatusType.OK]: {
    localeKey: 'over',
    icon: 'fa-check-circle',
    color: TagTypes.SUCCESS.color,
    backgroundColor: TagTypes.SUCCESS.backgroundColor,
  },

  [TripStatusType.TRACKED]: {
    localeKey: 'tracked',
    icon: 'fa-bus',
    color: TagTypes.SUCCESS.color,
    backgroundColor: TagTypes.SUCCESS.backgroundColor,
  },

  [TripStatusType.ROUTING]: {
    localeKey: 'routing',
    icon: 'fa-bus',
    color: TagTypes.SUCCESS.color,
    backgroundColor: TagTypes.SUCCESS.backgroundColor,
  },

  [TripStatusType.UNTRACKED]: {
    localeKey: 'untracked',
    icon: 'fa-circle-exclamation',
    color: TagTypes.DANGER.color,
    backgroundColor: TagTypes.DANGER.backgroundColor,
  },

  [TripStatusType.PROBLEM]: {
    localeKey: 'problem',
    icon: 'fa-circle-exclamation',
    color: TagTypes.WARNING.color,
    backgroundColor: TagTypes.WARNING.backgroundColor,
  },

  [TripStatusType.NO_DATA]: {
    localeKey: 'noData',
    icon: 'fa-circle-question',
    color: TagTypes.NEUTRAL.color,
    backgroundColor: TagTypes.NEUTRAL.backgroundColor,
  },

  [TripStatusType.SCHEDULED]: {
    localeKey: 'scheduled',
    icon: 'fa-clock',
    color: TagTypes.NEUTRAL.color,
    backgroundColor: TagTypes.NEUTRAL.backgroundColor,
  },
};

export const TRIPS_LS_COLUMNS = 'tripListNew/columnsSelection';

/** @enum {string} */
export const ColumnKey = {
  ARRIVAL_TIME: 'arrivalTimeValue',
  COMMENT: 'commentValue',
  DELAY: 'delay',
  DEPARTURE_DATE: 'departure_time',
  DEPARTURE_TIME: 'departureTimeValue',
  DEVICE: 'devicesName',
  DEVICE_TEAM: 'deviceTeamValue',
  DRIVERS: 'formattedDrivers',
  DUTY: 'block_id',
  FIRST_STOP: 'firstStopValue',
  FORMATTED_NAME: 'formatted_name',
  GTFS: 'gtfsValue',
  HEADSIGN: 'headsign',
  LAST_STOP: 'lastStopValue',
  MESSAGES: 'messages',
  OFF_ITINERARY_KM: 'off_itinerary_km',
  PASSENGER_COUNT_ALIGHTINGS: 'passengerCountAlightingsValue',
  PASSENGER_COUNT_BOARDINGS: 'passengerCountBoardingValue',
  PASSENGER_COUNT_LOADING: 'passengerCountLoadingValue',
  PERCENT_KM: 'percentKmValue',
  RECORDED_ARRIVAL_TIME: 'recordedArrivalTimeValue',
  RECORDED_DEPARTURE_TIME: 'recordedDepartureTimeValue',
  ROUTE: 'routeValue',
  SERVICE: 'serviceValue',
  STATUS: 'tripStatusValue',
  STOP_INFO: 'stopInfoValue',
  TAB_TRIP_STATUS: 'tabTripStatus',
  TEAM: 'tripTeamValue',
  TK: 'tkValue',
  TRIP_MODIFICATION: 'updates',
  UNRELIABLE_KM: 'unreliableKmValue',
  VALIDATION: 'validation',
  VEHICLES: 'formattedVehicles',
  VK: 'vkOrReliableKm',
  VK_NO_STATUS: 'vkNoStatusValue',
};

/** @enum {import('@/components/Table/DataGridVuetify/models/DataGrid.models').CellBuilder} */
const CellBuilders = {
  STOP([value, object], { groupId, stopKey }) {
    return value
      ? {
          component: ClickableCell,
          props: {
            highlight: true,
            displayValue: value,
            link: {
              name: GroupRoute.STOP_DETAILED,
              params: { groupId, stopId: object[stopKey].id },
              query: {
                date: dateToFormattedString(object.departure_time, {
                  format: DatetimeFormat.GTFS_DATE_QUERY,
                  unix: true,
                }),
              },
            },
          },
        }
      : { value: NO_DATA };
  },
};

export const getDatagrid = () => {
  const ls = JSON.parse(localStorage.getItem(TRIPS_LS_COLUMNS));
  const deactivatedRoutes = store.getters.group.deactivated_routes;

  // Helpers
  const defaultNotSelected = columnType => ls && ls.includes(columnType);
  const defaultSelected = columnType => !ls || ls.includes(columnType);

  return new DataGrid({
    name: 'tripListDatagrid',
    searchFields: ['formatted_name'],
    defaultSortBy: [{ key: ColumnKey.DEPARTURE_DATE, order: SortOrder.ASC }],
    columnSelectionLocalStorageKey: TRIPS_LS_COLUMNS,
    hasActions: false,

    columns: [
      // FORMATTED_NAME
      new DataGridColumn({
        key: ColumnKey.FORMATTED_NAME,
        title: 'columnTripList.tripName',
        defaultSelected: defaultSelected(ColumnKey.FORMATTED_NAME),
        selectable: false,
        cellBuilder([value, object], { groupId, tripId }) {
          const deviceId = object.devices?.[0]?.id;
          const to = store.getters.hasPermission(Permission.VIEW_TRIP_VIEW)
            ? {
                name: GroupRoute.TRIP_DETAILED,
                params: { groupId, tripId },
                query: { date: object.tripDate, deviceId },
              }
            : null;
          return {
            component: ClickableCell,
            props: {
              highlight: true,
              displayValue: value,
              link: to,
            },
          };
        },
      }),

      // TRIP STATUS
      new DataGridColumn({
        key: ColumnKey.STATUS,
        title: 'columnTripList.tripStatus',
        defaultSelected: defaultSelected(ColumnKey.STATUS),
        filterable: true,
        selectable: false,
        cellBuilder([value, object], { toggleChildren }) {
          return object.status || object.trips?.length > 0
            ? {
                component: CellTripStatus,
                props: {
                  status: object.status,
                  problems: object.problems,
                  hasChildren: object.trips?.length > 0,
                },
                events: { toggleChildren },
                value: object.status,
              }
            : {
                value: NO_DATA,
              };
        },
      }),

      // ROUTE
      new DataGridColumn({
        key: ColumnKey.ROUTE,
        title: 'columnTripList.route',
        defaultSelected: defaultSelected(ColumnKey.ROUTE),
        filterable: true,
        cellBuilder([value, object]) {
          const route = object.route;
          return {
            component: RouteBadge,
            props: {
              isExperimental: deactivatedRoutes.includes(route.id),
              route: {
                route_color: route.color,
                route_text_color: route.text_color,
              },
              value,
            },
          };
        },
      }),

      // PERCENT_KM
      new DataGridColumn({
        key: ColumnKey.PERCENT_KM,
        title: 'columnTripList.progression',
        defaultSelected: defaultSelected(ColumnKey.PERCENT_KM),
        cellBuilder([value]) {
          if (!value) return null;
          return {
            component: CellProgress,
            props: {
              value,
            },
          };
        },
      }),

      // DEPARTURE_DATE
      new DataGridColumn({
        key: ColumnKey.DEPARTURE_DATE,
        title: 'columnTripList.departureDate',
        defaultSelected: defaultNotSelected(ColumnKey.DEPARTURE_DATE),
        cellBuilder([value]) {
          if (!value) return null;
          return {
            component: DateCell,
            props: {
              date: new Date(value * 1000),
              options: { dateStyle: 'short' },
            },
          };
        },
      }),

      // GTFS
      new DataGridColumn({
        key: ColumnKey.GTFS,
        title: 'columnTripList.transportPlan',
        defaultSelected: defaultNotSelected(ColumnKey.GTFS),
        /** @param {[import('@/api').TripListItem['gtfs']]} apiValues */
        cellBuilder([value]) {
          return {
            component: CellGtfs,
            props: { gtfs: value },
          };
        },
      }),

      // TEAM
      ...(store.getters.hasPermission(Permission.VIEW_TEAM_COLUMN)
        ? [
            new DataGridColumn({
              key: ColumnKey.TEAM,
              title: 'columnTripList.tripTeam',
              defaultSelected: defaultNotSelected(ColumnKey.TEAM),
              filterable: value => ({ value, label: store.getters.getTeamNameById(value) }),
              cellBuilder([value, object]) {
                return {
                  component: TeamDisplay,
                  props: {
                    teamId: value,
                    color: object.tripTeamColor,
                  },
                };
              },
            }),
          ]
        : []),

      // DUTY
      new DataGridColumn({
        key: ColumnKey.DUTY,
        title: 'columnTripList.duty',
        defaultSelected: defaultNotSelected(ColumnKey.DUTY),
        filterable: true,
      }),

      // DEVICE
      ...(store.getters.hasPermission(Permission.VIEW_TRIP_DEVICES)
        ? [
            new DataGridColumn({
              key: ColumnKey.DEVICE,
              title: 'columnTripList.device',
              defaultSelected: defaultNotSelected(ColumnKey.DEVICE),
              filterable: true,
              cellBuilder([value, object], { groupId, date }) {
                return object.devices
                  ? {
                      component: CellDevice,
                      props: {
                        date,
                        devices: object.devices,
                        groupId,
                      },
                    }
                  : { value: NO_DATA };
              },
            }),
          ]
        : []),

      // DEVICE_TEAM
      new DataGridColumn({
        key: ColumnKey.DEVICE_TEAM,
        title: 'columnTripList.deviceTeam',
        defaultSelected: defaultNotSelected(ColumnKey.DEVICE_TEAM),
        filterable: value => ({ value, label: store.getters.getTeamNameById(value) }),
        cellBuilder([value, object]) {
          return {
            component: TeamDisplay,
            props: {
              teamId: object.deviceTeamValue,
              color: object.deviceTeamColor,
            },
          };
        },
      }),

      // DELAY
      new DataGridColumn({
        key: ColumnKey.DELAY,
        title: 'columnTripList.delay',
        defaultSelected: defaultNotSelected(ColumnKey.DELAY),
        cellBuilder([value, object]) {
          const delay = !value || value.length === 0 ? 0 : value;
          // Display "-" only when last stop has not been served
          if (delay === 0 && object.recordedArrivalTimeValue === '-') return null;
          return {
            component: CellDelay,
            props: { delay },
          };
        },
      }),

      // VK
      ...(store.getters.hasPermission(Permission.VIEW_TRIP_KM)
        ? [
            new DataGridColumn({
              key: ColumnKey.VK,
              title: 'columnTripList.vk',
              defaultSelected: defaultNotSelected(ColumnKey.VK),
            }),
          ]
        : []),

      // TK
      ...(store.getters.hasPermission(Permission.VIEW_TRIP_KM)
        ? [
            new DataGridColumn({
              key: ColumnKey.TK,
              title: 'columnTripList.tk',
              defaultSelected: defaultNotSelected(ColumnKey.TK),
            }),
          ]
        : []),

      // UNRELIABLE KM
      ...(store.getters.hasPermission(Permission.VIEW_TRIP_KM)
        ? [
            new DataGridColumn({
              key: ColumnKey.UNRELIABLE_KM,
              title: 'columnTripList.unreliable_km',
              defaultSelected: defaultNotSelected(ColumnKey.UNRELIABLE_KM),
            }),
          ]
        : []),

      // OFF ITINERARY KM
      ...(store.getters.hasPermission(Permission.VIEW_TRIP_KM)
        ? [
            new DataGridColumn({
              key: ColumnKey.OFF_ITINERARY_KM,
              title: 'columnTripList.off_itinerary_km',
              defaultSelected: defaultNotSelected(ColumnKey.OFF_ITINERARY_KM),
            }),
          ]
        : []),

      // TRIP HEADSIGN
      new DataGridColumn({
        key: ColumnKey.HEADSIGN,
        title: 'columnTripList.headsign',
        filterable: true,
        defaultSelected: defaultNotSelected(ColumnKey.HEADSIGN),
      }),

      // SERVICE
      new DataGridColumn({
        key: ColumnKey.SERVICE,
        title: 'columnTripList.service',
        defaultSelected: defaultNotSelected(ColumnKey.SERVICE),
        cellBuilder([value, object]) {
          if (!object.service) return { value: NO_DATA };
          return {
            component: CellProgress,
            props: {
              value,
              service: object.service,
            },
          };
        },
      }),

      // FIRST_STOP
      new DataGridColumn({
        key: ColumnKey.FIRST_STOP,
        title: 'columnTripList.firstStop',
        filterable: true,
        defaultSelected: defaultNotSelected(ColumnKey.FIRST_STOP),
        cellBuilder: CellBuilders.STOP,
      }),

      // DEPARTURE_TIME
      new DataGridColumn({
        key: ColumnKey.DEPARTURE_TIME,
        title: 'columnTripList.departureTime',
        defaultSelected: defaultNotSelected(ColumnKey.DEPARTURE_TIME),
      }),

      // RECORDED_DEPARTURE_TIME
      new DataGridColumn({
        key: ColumnKey.RECORDED_DEPARTURE_TIME,
        title: 'columnTripList.recordedDepartureTime',
        defaultSelected: defaultNotSelected(ColumnKey.RECORDED_DEPARTURE_TIME),
      }),

      // LAST_STOP
      new DataGridColumn({
        key: ColumnKey.LAST_STOP,
        title: 'columnTripList.lastStop',
        filterable: true,
        defaultSelected: defaultNotSelected(ColumnKey.LAST_STOP),
        cellBuilder: CellBuilders.STOP,
      }),

      // ARRIVAL_TIME
      new DataGridColumn({
        key: ColumnKey.ARRIVAL_TIME,
        title: 'columnTripList.arrivalTime',
        defaultSelected: defaultNotSelected(ColumnKey.ARRIVAL_TIME),
      }),

      // RECORDED_ARRIVAL_TIME
      new DataGridColumn({
        key: ColumnKey.RECORDED_ARRIVAL_TIME,
        title: 'columnTripList.recordedArrivalTime',
        defaultSelected: defaultNotSelected(ColumnKey.RECORDED_ARRIVAL_TIME),
      }),

      // DRIVERS
      new DataGridColumn({
        key: ColumnKey.DRIVERS,
        value: item => (item.formattedDrivers ? item.formattedDrivers[0].text : NO_DATA),
        title: 'columnTripList.driver',
        sortable: false,
        filterable: true,
        defaultSelected: defaultNotSelected(ColumnKey.DRIVERS),
        cellBuilder([value, object]) {
          if (!object.formattedDrivers) return null;
          return {
            component: CellDropList,
            props: { values: object.formattedDrivers },
          };
        },
      }),

      // VEHICLES
      new DataGridColumn({
        key: ColumnKey.VEHICLES,
        value: item => (item.formattedVehicles ? item.formattedVehicles[0].text : NO_DATA),
        title: 'columnTripList.vehicle',
        sortable: false,
        filterable: true,
        defaultSelected: defaultNotSelected(ColumnKey.VEHICLES),
        cellBuilder([value, object]) {
          if (!object.formattedVehicles) return null;
          return {
            component: CellDropList,
            props: { values: object.formattedVehicles },
          };
        },
      }),

      // PASSENGER COUNT BOARDINGS
      new DataGridColumn({
        key: ColumnKey.PASSENGER_COUNT_BOARDINGS,
        title: 'columnTripList.passengerCountBoardings',
        defaultSelected: defaultNotSelected(ColumnKey.PASSENGER_COUNT_BOARDINGS),
      }),

      // PASSENGER COUNT ALIGHTINGS
      new DataGridColumn({
        key: ColumnKey.PASSENGER_COUNT_ALIGHTINGS,
        title: 'columnTripList.passengerCountAlightings',
        defaultSelected: defaultNotSelected(ColumnKey.PASSENGER_COUNT_ALIGHTINGS),
      }),

      // PASSENGER COUNT LOADING
      new DataGridColumn({
        key: ColumnKey.PASSENGER_COUNT_LOADING,
        title: 'columnTripList.passengerCountLoading',
        defaultSelected: defaultNotSelected(ColumnKey.PASSENGER_COUNT_LOADING),
      }),

      // TRIP MODIFICATION
      new DataGridColumn({
        key: ColumnKey.TRIP_MODIFICATION,
        value: item => {
          // display the number of updates
          let modificationsCount = 0;

          Object.entries(item.updates).forEach(([key, value]) => {
            if ([UpdateType.COMMENT, UpdateType.STOP_INFO].includes(key)) return; // don't count comment and stop info
            Array.isArray(value) ? (modificationsCount += value.length) : (modificationsCount += 1);
          });
          return modificationsCount;
        },
        title: 'columnTripList.tripModification',
        defaultSelected: defaultNotSelected(ColumnKey.TRIP_MODIFICATION),
        cellBuilder([value, object], { redirectToModificationPage }) {
          return {
            component: CellTripModification,
            props: {
              updates: object.updates,
              hasChildren: object.trips?.length > 0,
              hasEditPermission: store.getters.hasPermission(Permission.EDIT_TRIP_UPDATES),
              tripId: object.defaultId,
            },
            events: { redirectToModificationPage },
          };
        },
      }),

      // STOP INFO
      ...(store.getters.hasPermission(Permission.VIEW_STOP_INFO)
        ? [
            new DataGridColumn({
              key: ColumnKey.STOP_INFO,
              value: item => item.stopInfoValue?.length,
              defaultSelected: defaultNotSelected(ColumnKey.STOP_INFO),
              title: 'columnTripList.stopInfo',
              cellBuilder([value, object], { showModal }) {
                return {
                  component: CellStopInfo,
                  props: {
                    hasChildren: object.trips?.length > 0,
                    hasEditPermission: store.getters.hasPermission(Permission.EDIT_TRIP_UPDATES),
                    stopInfo: object.stopInfoValue || [],
                    tripId: object.defaultId,
                  },
                  events: { showModal },
                };
              },
            }),
          ]
        : []),

      // MESSAGES
      ...(store.getters.hasPermission(Permission.CREATE_MESSAGES)
        ? [
            new DataGridColumn({
              key: ColumnKey.MESSAGES,
              title: 'columnTripList.messages',
              sortable: false,
              defaultSelected: defaultNotSelected(ColumnKey.MESSAGES),
              cellBuilder([value], { showModal }) {
                return {
                  component: CellMessages,
                  props: { hasChildren: !!value },
                  events: { showModal },
                };
              },
            }),
          ]
        : []),

      // COMMENT
      ...(store.getters.hasPermission(Permission.VIEW_TRIP_COMMENTS)
        ? [
            new DataGridColumn({
              key: ColumnKey.COMMENT,
              title: 'columnTripList.comment',
              defaultSelected: defaultNotSelected(ColumnKey.COMMENT),
              cellBuilder([value], { showModal }) {
                return {
                  component: CommentCell,
                  props: { comment: value },
                  events: { showModal },
                };
              },
            }),
          ]
        : []),
    ],
  });
};
