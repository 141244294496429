<script setup lang="ts">
import dayjs from 'dayjs';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import { trips } from '@/api';
import ModalBlockedView from '@/components/common/ModalBlockedView.vue';
import MapboxMap from '@/components/map/MapboxMap.vue';
import MapboxShapeEdition from '@/components/map/MapboxShapeEdition.vue';
import Btn from '@/components/ui/Btn.vue';
import HeaderDatepicker from '@/components/ui/HeaderDatepicker.vue';
import Calendar from '@/libs/calendar';
import { dateObjToGtfsFormat } from '@/libs/helpers/dates';
import { clearEmptyValues } from '@/libs/helpers/objects';
import { GroupRoute } from '@/libs/routing';
import { FstType, useTripUpdates } from '@/store-pinia/trip-updates';

import type { TripListItemV4 } from '@/@types/api/tripList';
import type { Shape, Trip } from '@/@types/gtfs';
import type { Group } from '@/@types/group';
import type { EditedShape, MapStop } from '@/@types/mapbox';
import { HighlightType } from '@/@types/mapbox';

import StopTimesFeed from './StopTimesFeed.vue';
import { LngLat, LngLatBounds } from 'mapbox-gl';
import { MapboxHelper } from '@/components/map/mapboxHelper';

const store = useStore();
const tripUpStore = useTripUpdates();
const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const props = defineProps({
  tripId: {
    required: true,
    type: String,
  },
});

const isBlockedModalShown = ref<Boolean>(false);
const loading = ref<Boolean>(false);
const trip = ref<TripListItemV4>();

const routeColor = ref<string>('#000000');

const startDateValue = ref<Date>(new Date());
const endDateValue = ref<Date>(new Date());
const disabledDates = ref<{
  minDate: Date | null;
  maxDate: Date | null;
}>({
  minDate: null,
  maxDate: null,
});

const group = computed<Group>(() => {
  return store.getters.group;
});

const selectedDate = computed<string>(() => {
  if (route.query.date) {
    const date = dayjs(route.query.date as string)
      .hour(23)
      .minute(59)
      .second(59)
      .utc()
      .toDate();
    return dateObjToGtfsFormat(date);
  }
  return dateObjToGtfsFormat(new Date());
});

const blockedModalList = computed(() => {
  // generate translated text for each bullet point of blocked modal
  return [...Array(5)].map((_, i) => [
    t(`deviationsFeatureBlockedModal.fullString${i + 1}`),
    t(`deviationsFeatureBlockedModal.highlightedString${i + 1}`),
  ]);
});

const deviceId = computed(() => {
  if (route.query.deviceId) {
    return route.query.deviceId;
  } else if (!trip.value) {
    return null;
  } else if (!Array.isArray(trip.value.devices)) {
    return trip.value.devices;
  } else if (0 < trip.value.devices.length) {
    return trip.value.devices[0].id;
  }

  return null;
});

const gtfsId = computed<string>(() => {
  if (trip.value?.gtfs) return trip.value.gtfs[0].id;
  // If no data in trip, return empty
  return '';
});

const limitedDelay = computed<number | null>({
  get() {
    return tripUpStore.delay ? tripUpStore.delay / 60 : null;
  },

  set(val: number | null) {
    let valueToSet = null;
    if (!val) valueToSet = null;
    else if (val > 999) valueToSet = 999;
    else if (val < -999) valueToSet = -999;
    else valueToSet = val;
    tripUpStore.delay = valueToSet ? valueToSet * 60 : null;
  },
});

const hasTripUpdates = computed<boolean>(() => {
  if (trip.value) {
    return Object.keys(trip.value?.updates).length !== 0;
  }
  return false;
});

const hasDateRangeChanged = computed<boolean>(() => {
  const queryDate = dayjs(route.query.date as string)
    .hour(23)
    .minute(59)
    .second(59)
    .utc()
    .toDate();
  if (
    (dateObjToGtfsFormat(startDateValue.value) !== dateObjToGtfsFormat(queryDate) ||
      dateObjToGtfsFormat(endDateValue.value) !== dateObjToGtfsFormat(queryDate)) &&
    hasTripUpdates.value
  ) {
    return true;
  }
  return false;
});

onMounted(async () => {
  loading.value = true;
  await router.isReady();

  await getTrip();
  if (trip.value && gtfsId.value) {
    await tripUpStore.initStore(gtfsId.value, props.tripId, trip.value.updates);
  }

  if (route.query.date) {
    const date: Date = dayjs(route.query.date as string)
      .hour(23)
      .minute(59)
      .second(59)
      .utc()
      .toDate();
    startDateValue.value = date || new Date();
    endDateValue.value = date || new Date();
  }

  const routes = await store.dispatch('gtfs/getRoutesMap', {
    gtfsId: gtfsId.value,
  });

  // route color for design
  const thisRoute = routes[tripUpStore.trip.route_id] || null;
  routeColor.value = thisRoute?.route_color ? `#${thisRoute.route_color}` : '#000000';

  // Define calendar inactive dates to limit date picker
  disabledDates.value = await Calendar.getInactiveDatesForATrip(tripUpStore.trip.service_id, gtfsId.value);

  loading.value = false;
});

onUnmounted(() => {
  tripUpStore.$reset();
});

/**
 * Cancel a stop
 */
function cancelStop(event: mapboxgl.MapLayerMouseEvent, stopSequence?: number) {
  if (!tripUpStore.inStopTimeEdition && !tripUpStore.inShapeEdition) {
    const stopId = event?.features?.[0]?.properties?.id;
    const stops = tripUpStore.feedStopTimes.filter(stop => stop.stop_id === stopId);

    const currentStopSequence = stopSequence || stops[0].stop_sequence;
    scrollToElement(stopId + currentStopSequence);

    // Normal stop - no duplicate
    if (stops.length === 1) {
      // only update on click if stop is regular/canceled
      if ([FstType.CANCELED, FstType.REGULAR].includes(stops[0].type)) {
        const typeToSet = stops[0].type === FstType.CANCELED ? FstType.REGULAR : FstType.CANCELED;
        const indexToUpdate = tripUpStore.feedStopTimes.findIndex(stop => stop.stop_id === stopId);
        if (indexToUpdate !== -1) tripUpStore.feedStopTimes[indexToUpdate].type = typeToSet;
      }

      // Stop with multiple services
      // if there is a stopSequence, it means that the tooltip has been clicked
    } else if (typeof stopSequence === 'number') {
      const indexToUpdate = tripUpStore.feedStopTimes.findIndex(stop => stop.stop_sequence === stopSequence);
      if (
        indexToUpdate !== -1 &&
        [FstType.CANCELED, FstType.REGULAR].includes(tripUpStore.feedStopTimes[indexToUpdate].type)
      ) {
        const typeToSet =
          tripUpStore.feedStopTimes[indexToUpdate].type === FstType.CANCELED
            ? FstType.REGULAR
            : FstType.CANCELED;
        if (indexToUpdate !== -1) tripUpStore.feedStopTimes[indexToUpdate].type = typeToSet;
      }
    }
  }
}

async function submitTripModification() {
  const tripUpdates = {
    query: {
      gtfs_id: gtfsId.value,
      trip_id: props.tripId,
      start_date: dateObjToGtfsFormat(startDateValue.value),
      end_date: dateObjToGtfsFormat(endDateValue.value),
    },
    body: clearEmptyValues(tripUpStore.updatedTripUpdate),
    many: dateObjToGtfsFormat(startDateValue.value) !== dateObjToGtfsFormat(endDateValue.value),
  };

  await store.dispatch('trips/updateTrip', tripUpdates);

  router.push({
    name: GroupRoute.TRIP_DETAILED,
    params: { groupId: group.value._id, tripId: props.tripId },
    query: { date: route.query.date, deviceId: route.query.deviceId },
  });
}

function onMapLoad({ map }: { map: mapboxgl.Map }) {
  map.once('idle', () => {
    mapInstance.value = map;
    // handle case order missmatch because of edited shape
    setTimeout(() => {
      MapboxHelper.defaultLayerOrderReset(map);
    }, 300);
  });
}

function limitDelayLength(e: KeyboardEvent) {
  const maxLen = tripUpStore.delay || 0 < 0 ? 4 : 3;
  if (tripUpStore.delay && tripUpStore.delay.toString().length === maxLen && e.key !== 'Backspace') {
    e.preventDefault();
  }
}

/**
 * Scroll to the stop in the timeline
 */
function scrollToElement(stopId: string) {
  const element = document.getElementById(`stop-${stopId}`);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
}

async function getTrip() {
  const data = await trips.getTripFromTripList(group.value._id, selectedDate.value, props.tripId, true);
  trip.value =
    data.trips && data.trips.length > 0
      ? data.trips.find(trip => trip.devices[0].id === deviceId.value)
      : data;
}

function showBlockedModal() {
  isBlockedModalShown.value = true;
}

// #region Map
const mapBounds = ref<mapboxgl.LngLatBounds>();
const mapInstance = ref<mapboxgl.Map | null>(null);
const editedShape = ref<EditedShape | null>(null);

const mapStops = computed<Array<MapStop>>(() => {
  const mapStopList: Array<MapStop> = [];
  tripUpStore.feedStopTimes.forEach(fst => {
    mapStopList.push({
      id: fst.stop_id,
      highlight: false,
      unserved: fst.type === FstType.CANCELED,
      deviation: [FstType.AD_HOC, FstType.REUSE, FstType.SHIFT].includes(fst.type),
      editionHighlight:
        tripUpStore.stopEditionRangeHighlight.length === 0
          ? true
          : tripUpStore.stopEditionRangeHighlight.includes(fst.stop_sequence),
      stop_sequence: fst.stop_sequence,
    });
  });

  return mapStopList;
});

const boundsFocused = ref<boolean>(false);

// Handle focus on full line, or on specific interval depending on stopEditionRangeHighlight
watch(
  [() => tripUpStore.stopEditionRangeHighlight, () => tripUpStore.getAllStops],
  () => {
    if (tripUpStore.stopEditionRangeHighlight.length === 0) boundsFocused.value = false;
    if (!boundsFocused.value) {
      const bounds = new LngLatBounds();
      const stopsToDisplay =
        tripUpStore.stopEditionRangeHighlight?.length > 0
          ? mapStops.value.filter(stop => stop.editionHighlight)
          : mapStops.value;
      stopsToDisplay.forEach(stop => {
        const stopElem = tripUpStore.getAllStops.get(stop.id);
        if (stopElem) bounds.extend(new LngLat(stopElem.stop_lon, stopElem.stop_lat));
      });
      if (!bounds.isEmpty()) mapBounds.value = bounds;
    }
    if (tripUpStore.stopEditionRangeHighlight.length > 0 && !boundsFocused.value) boundsFocused.value = true;
  },
  { deep: true, immediate: true },
);

/**
 * Fly action on map based on stopId
 */
function flyTo(stopId: string) {
  const relatedStop = tripUpStore.getAllStops.get(stopId);
  if (!relatedStop) return;
  mapInstance.value?.flyTo({
    center: [relatedStop.stop_lon, relatedStop.stop_lat],
    zoom: 15,
    speed: 0.8,
  });
}

function switchShapeEditionMode() {
  tripUpStore.inShapeEdition = !tripUpStore.inShapeEdition;
  if (tripUpStore.inShapeEdition) {
    setShapeInEdition();
  }
}

function setShapeInEdition() {
  const shapesGtfs: { [shapeId: string]: Shape } = store.getters['gtfs/getCachedGtfsTable'](
    gtfsId.value,
    'shapes',
  );
  const tripsGtfs: { [tripId: string]: Trip } = store.getters['gtfs/getCachedGtfsTable'](
    gtfsId.value,
    'trips',
  );
  const trip = tripsGtfs[props.tripId];

  if (trip) {
    const shape = shapesGtfs[trip.shape_id];
    if (shape) {
      const coords = tripUpStore.editedShape || shape.geometry.coordinates;

      editedShape.value = {
        coordinates: coords,
        id: trip.shape_id,
        snapRoad: true,
        waypoints: coords.map(coordinates => ({ coordinates, snapRoad: false })),
      };
    }
  }
}
// #enregion
</script>

<template>
  <div class="trip-modification">
    <div class="trip-modification__left">
      <div class="trip-modification__left-header">
        <div
          class="trip-modification__cancel-trip"
          :class="{ 'trip-modification__cancel-trip--is-canceled': tripUpStore.isCanceled }"
        >
          <v-checkbox id="cancel-trip" v-model="tripUpStore.isCanceled" color="success" hide-details>
            <template #label>
              <span class="trip-modification__part-title">
                {{ $t('cancelTrip') }}
              </span>
            </template>
          </v-checkbox>
        </div>
        <div class="d-flex ga-2">
          <label for="delay">
            {{ $t('addDelay') }}
          </label>
          <input
            id="delay"
            v-model.number="limitedDelay"
            type="number"
            :min="-999"
            placeholder="00"
            :max="999"
            class="form-group__input form-group__small-number"
            @keydown="limitDelayLength"
          />
        </div>
      </div>
      <StopTimesFeed
        :route-color="routeColor"
        :delay="tripUpStore.delay ? tripUpStore.delay / 60 : null"
        :date="selectedDate"
        @mapFocusStop="flyTo"
        @showBlockedModal="showBlockedModal"
      />
      <div ref="tripModificationFooter" class="trip-modification__left-footer">
        <div class="trip-modification__period-select">
          <span class="period-select__label">{{ $t('from') }}</span>
          <HeaderDatepicker
            v-model:value="startDateValue"
            :disabled="disabledDates"
            :has-custom-position="false"
            without-arrows
            class="datepicker-input"
          />
          <span class="period-select__label">{{ $t('to') }}</span>
          <HeaderDatepicker
            v-model:value="endDateValue"
            :disabled="disabledDates"
            :has-custom-position="false"
            without-arrows
            class="datepicker-input"
          />
        </div>
        <div class="trip-modification__btn-container">
          <Btn
            type="secondary"
            @click="
              router.push({
                name: GroupRoute.TRIP_DETAILED,
                params: { groupId: group._id, tripId: tripId },
                query: { date: route.query.date },
              })
            "
          >
            {{ $t('cancel') }}
          </Btn>
          <Btn
            type="primary"
            :disabled="
              (!tripUpStore.hasTripUpdatesChanges && !hasDateRangeChanged) ||
              tripUpStore.inStopTimeEdition ||
              tripUpStore.inShapeEdition
            "
            @click="submitTripModification"
          >
            <font-awesome-icon icon="fa-check" />
            {{ $refs.tripModificationFooter?.offsetWidth > 1000 ? $t('apply') : $t('applyShort') }}
          </Btn>
        </div>
      </div>
    </div>

    <div class="trip-modification__right">
      <div class="trip-modification__map">
        <div class="trip-modification__map__edit-btn">
          <Btn
            type="secondary"
            @click="
              tripUpStore.allowDeviationFeature && !tripUpStore.inShapeEdition
                ? switchShapeEditionMode()
                : showBlockedModal()
            "
          >
            <font-awesome-icon icon="fa-solid fa-arrows-split-up-and-left" class="mr-1" />
            <span>{{ $t('modifyPath') }}</span>
          </Btn>
        </div>
        <MapboxMap
          v-model:bounds="mapBounds"
          border-radius="0 4px 0 0"
          :gtfs-id="gtfsId"
          :stops="mapStops"
          :trips="[{ id: tripId, highlight: HighlightType.NONE }]"
          :stops-options="{
            stopsZones: false,
            stopsBigMarkers: true,
            showUnserved: true,
            stopSelectorData: tripUpStore.trip.stop_times,
          }"
          trip-update-mode
          @load="onMapLoad"
          @click:stop="cancelStop($event)"
          @click:stopTooltip="cancelStop($event.event, $event.stopSequence)"
        >
          <MapboxShapeEdition
            v-if="mapInstance && editedShape && tripUpStore.inShapeEdition"
            :map="mapInstance"
            :edited-shape="editedShape"
            :route-color="routeColor"
          />
          <div v-if="tripUpStore.isCanceled" class="trip-modification__disabled-trip">
            <span class="trip-modification__disabled-trip__title">
              {{ $t('canceledTrip') }}
            </span>
            <Btn type="secondary" @click="tripUpStore.isCanceled = !tripUpStore.isCanceled">
              <font-awesome-icon icon="fa-rotate-right" />
              <span>{{ $t('restoreTrip') }}</span>
            </Btn>
          </div>
        </MapboxMap>
      </div>
    </div>

    <ModalBlockedView
      v-if="isBlockedModalShown"
      :title="$t('advancedDeviations')"
      :closable="true"
      :text="blockedModalList"
      @close="isBlockedModalShown = false"
    />
  </div>
</template>

<style lang="scss">
.trip-modification {
  display: flex;
  width: 100%;
  height: 100%;

  &__left {
    width: 50%;
    min-width: 650px;
  }

  &__left-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    border-bottom: 1px solid $border;
    padding-inline: $view-standard-padding;
  }

  &__cancel-trip {
    padding: 10px;
    border: 1px solid $border;
    border-radius: 6px;

    label {
      font-weight: $font-weight-semi-bold;
    }

    &--is-canceled {
      border-color: $primary-light;
      background-color: $transparent-primary;
    }
  }

  &__right {
    width: 50%;
  }

  &__map {
    position: relative;
    width: 100%;
    min-width: 400px;

    // 61px for nav bar top
    height: calc(100vh - 61px);

    &__edit-btn {
      position: absolute;
      top: 10px;
      right: 50px;
      z-index: 2;

      i {
        width: 14px;
        min-width: 14px;
        margin-right: 4px;
      }

      button {
        height: 29px;
      }
    }
  }

  &__btn-container {
    display: flex;
    width: 50%;

    button {
      width: 50%;
    }

    .fa-check {
      margin-right: 10px;
    }
  }

  &__disabled-trip {
    position: absolute;
    z-index: 3;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgb(235 87 87 / 80%);
    color: white;
    backdrop-filter: blur(5px);

    &__title {
      font-weight: $font-weight-semi-bold;
      font-size: 36px;
      font-family: $font-poppins;
    }
  }

  &__left-footer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    border-top: 1px solid $border;
    padding-inline: $view-standard-padding;
  }

  &__period-select {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
</style>

<i18n locale="fr">
{
  "apply": "Appliquer les modifications",
  "applyShort": "Appliquer",
  "tripModificationTitle": "Modification de la course",
  "minutes": "minutes",
  "advanceMinutes": "minute d'avance | minutes d'avance",
  "delayMinutes": "minute de retard | minutes de retard",
  "addDelay": "Indiquer un retard (min)",
  "cancelTrip": "Annuler la course",
  "modifyServicing": "Modifier la desserte",
  "restore": "Restaurer",
  "canceledTrip": "Course annulée",
  "restoreTrip": "Restaurer la course",
  "neutralized": "Neutralisé",
  "from": "Du",
  "to": "au",
  "modifyPath": "Modifier le tracé",
  "advancedDeviations": "Déviations avancées",
}
</i18n>

<i18n locale="en">
{
  "apply": "Apply changes",
  "applyShort": "Apply",
  "tripModificationTitle": "Trip modification",
  "minutes": "minutes",
  "advanceMinutes": "minute early | minutes early",
  "delayMinutes": "minute late | minutes late",
  "addDelay": "Apply a delay (min)",
  "cancelTrip": "Cancel trip",
  "modifyServicing": "Modify service",
  "restore": "Restore",
  "canceledTrip": "canceled trip",
  "restoreTrip": "Restore trip",
  "neutralized": "Neutralized",
  "from": "From",
  "to": "to",
  "modifyPath": "Modify path",
  "advancedDeviations": "Advanced deviations",
}
</i18n>
