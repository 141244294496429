<script setup lang="ts">
import mapboxgl, { LngLatBounds, NavigationControl } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import merge from 'deepmerge';
import MapboxTrips from '@/components/map/MapboxTrips.vue';
import MapboxStops from '@/components/map/MapboxStops.vue';
import { computed, onMounted, ref, watch, type PropType, type Ref } from 'vue';
import type { MapStop, MapTrip, StopsOptions } from '@/@types/mapbox';
import i18n from '@/i18n';

const DEFAULT_STOPS_OPTIONS: Partial<StopsOptions> = {
  stationsMarkers: true,
  stopsMarkers: true,
  stopsZones: false,
  stopsBigMarkers: false,
};

const props = defineProps({
  borderRadius: {
    type: String,
    default: '0',
  },
  gtfsId: {
    type: String,
    required: true,
  },
  stops: {
    type: Array as PropType<Array<MapStop>>,
    required: true,
  },
  stopsOptions: {
    type: Object as PropType<Partial<StopsOptions>>,
    default: () => ({}),
  },
  trips: {
    type: Array as PropType<Array<MapTrip>>,
    required: true,
  },
  bounds: {
    type: Object as PropType<LngLatBounds> | null,
    default: null,
  },
  center: {
    type: Object as PropType<[number, number]>,
    default: null,
  },
  displayTooltip: {
    type: Boolean,
    default: false,
  },
  fullScreenOption: {
    type: Boolean,
    default: true,
  },
  tripUpdateMode: {
    type: Boolean,
    default: false,
  },
});
mapboxgl.accessToken =
  'pk.eyJ1IjoicHlzYWUiLCJhIjoiY2s0Y2hrYTlxMG50ODNra2R6ZGVudTR5aiJ9.sccZsmomeJ-zdW21vHcSYQ';

const emit = defineEmits([
  'mouseenter:trip',
  'mouseleave:trip',
  'mouseenter:stop',
  'mouseleave:stop',
  'update:bounds',
  'load',
  'click',
  'click:trip',
  'click:stop',
  'click:device',
  'click:stopTooltip',
]);

const mapLoaded = ref<Boolean>(false);
const tripsLoaded = ref<Boolean>(false);
const stopsLoaded = ref<Boolean>(false);
const language = ref<MapboxLanguage>();
const mapInstance = ref<mapboxgl.Map | null>(null);
const debouncedEventTimer = ref<NodeJS.Timeout | null>(null);
const mapdiv = ref<HTMLElement>() as Ref<HTMLElement>;

const cStopsOptions = computed<StopsOptions>(() => merge(DEFAULT_STOPS_OPTIONS, props.stopsOptions));

watch(
  [() => mapLoaded.value, () => props.center, () => props.bounds],
  ([mapLoaded, center, bounds]) => {
    if (mapLoaded) {
      updateMapPosition(center, bounds);
    }
  },
  { immediate: true, deep: true },
);

watch(
  () => i18n.global.locale,
  () => {
    if (mapInstance.value && language.value) {
      mapInstance.value.setStyle(
        language.value.setLanguage(mapInstance.value.getStyle(), i18n.global.locale),
      );
    }
  },
);

onMounted(async () => {
  language.value = new MapboxLanguage({ defaultLanguage: i18n.global.locale });

  // Init map
  const map = new mapboxgl.Map({
    container: mapdiv.value,
    style: 'mapbox://styles/mapbox/streets-v12',
    center: [0, 0],
    zoom: 13,
    localFontFamily: "'Poppins', Arial, sans-serif",
    projection: {
      name: 'mercator',
      center: [0, 30],
      parallels: [30, 30],
    },
  });

  const nav = new NavigationControl({ showCompass: false });
  if (props.fullScreenOption) {
    map.addControl(new mapboxgl.FullscreenControl(), 'top-right');
  }

  map.addControl(nav, 'top-right');

  map.addControl(language.value);

  await new Promise<void>(resolve => {
    map.on('load', () => {
      resolve();
    });
  });

  mapInstance.value = map;
  updateMapPosition();
  emit('load', { map });

  mapLoaded.value = true;

  // handle click on map to deselect a device
  mapInstance.value.on('click', e => {
    if (!e.features) {
      onMapClick(e);
    }
  });
});

function onMapClick(event: mapboxgl.MapLayerMouseEvent, type?: string) {
  if (debouncedEventTimer.value) clearTimeout(debouncedEventTimer.value);

  // Capture `feature` here because it seems to
  // disappear in timeout function
  const feature = (event.features || [])[0];

  debouncedEventTimer.value = setTimeout(
    (event, type) => {
      const eventName = `click${type ? `:${type}` : ''}` as
        | 'mouseenter:trip'
        | 'mouseleave:trip'
        | 'mouseenter:stop'
        | 'mouseleave:stop'
        | 'update:bounds'
        | 'load'
        | 'click'
        | 'click:trip'
        | 'click:stop'
        | 'click:device'
        | 'click:stopTooltip';

      // Re-set `feature` in event data
      if (feature) {
        event.features = [feature];
      }

      emit(eventName, event);
    },
    100,
    event,
    type,
  );
}

function updateMapPosition(
  center: [number, number] = props.center,
  bounds: mapboxgl.LngLatBounds = props.bounds,
) {
  if (mapInstance.value) {
    if (center) {
      if (mapInstance.value.getZoom() < 9) {
        mapInstance.value.setZoom(14);
      }
      mapInstance.value.panTo(center);
    } else if (bounds) {
      mapInstance.value.fitBounds(bounds, {
        padding: 40,
        animate: false,
      });
    }
  }
}
function onStopMouseEnter(event: mapboxgl.MapLayerMouseEvent) {
  if (mapInstance.value) mapInstance.value.getCanvasContainer().style.cursor = 'pointer';
  emit('mouseenter:stop', event);
}

function onStopMouseLeave(event: mapboxgl.MapLayerMouseEvent) {
  if (mapInstance.value) mapInstance.value.getCanvasContainer().style.cursor = '';
  emit('mouseleave:stop', event);
}
</script>

<template>
  <div id="mapdiv" ref="mapdiv" :style="{ borderRadius: borderRadius }">
    <MapboxTrips
      v-if="mapLoaded && mapInstance"
      :gtfs-id="gtfsId"
      :trips="trips"
      :map="mapInstance"
      :trip-update-mode="tripUpdateMode"
      @click="onMapClick($event, 'trip')"
      @mouseenter="$emit('mouseenter:trip', $event)"
      @mouseleave="$emit('mouseleave:trip', $event)"
      @isLoaded="tripsLoaded = true"
    />

    <MapboxStops
      v-if="tripsLoaded && mapInstance"
      :gtfs-id="gtfsId"
      :options="cStopsOptions"
      :stops="stops"
      :trip-update-mode="tripUpdateMode"
      :display-tooltip="displayTooltip"
      :map="mapInstance"
      @click="onMapClick($event, 'stop')"
      @mouseenter="onStopMouseEnter($event)"
      @mouseleave="onStopMouseLeave($event)"
      @tooltipToggleStop="onMapClick($event, 'stopTooltip')"
      @update:bounds="$emit('update:bounds', $event)"
      @isLoaded="stopsLoaded = true"
    />

    <slot v-if="stopsLoaded" :map="mapInstance" :on-map-click="onMapClick" />
  </div>
</template>

<style lang="scss">
#mapdiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Hides info button and logo on mapbox
.mapboxgl-ctrl-logo,
.mapboxgl-ctrl-attrib-inner,
.mapboxgl-ctrl-attrib.mapboxgl-compact {
  display: none !important;
}

.mapboxgl-ctrl-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: transparent;
  box-shadow: none !important;
}

.mapboxgl-ctrl-group button {
  border: 1px solid $border-variant !important;
  border-radius: 5px !important;
  background-color: $canvas;
}

.mapboxgl-ctrl button:not(:disabled):hover {
  background-color: rgb(255 255 255 / 80%);
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  margin-bottom: 25px;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon {
  background-image: url('../../assets/img/icons/up-right-and-down-left-from-center.svg');
}

.mapboxgl-ctrl button.mapboxgl-ctrl-shrink .mapboxgl-ctrl-icon {
  background-image: url('../../assets/img/icons/down-left-and-up-right-to-center.svg');
}
</style>
