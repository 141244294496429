<template>
  <div class="device">
    <div class="device-header">
      <div class="device-header__column device-header__column--justify-left border--right-grey">
        <div class="device-header__id-elements">
          <img alt="Smartphone" src="@/assets/img/picto_appareil_OP.svg?url" width="75" />
        </div>

        <div class="device-header__id-elements">
          <div class="device-header__name" :class="{ 'device-header__name--empty': isDeviceNameEmpty }">
            {{ name }}
          </div>

          <Btn type="primary" @click="modalMessageNewShown = true">
            {{ $t('contact') }}
          </Btn>
        </div>
      </div>

      <div class="device-header__column border--right-grey">
        <table class="details-table">
          <tbody>
            <tr>
              <td class="details-table__label">
                {{ $t(isDeviceConnected ? 'connected' : 'disconnected') }}
              </td>

              <td>
                <span class="dot-connection" :class="{ 'dot-connection--connected': isDeviceConnected }" />
              </td>
            </tr>

            <tr>
              <td class="details-table__label">
                {{ $t('lastDataReceived') }}
              </td>

              <td>
                {{ lastDataReceived }}
              </td>
            </tr>

            <tr>
              <td class="details-table__label">
                {{ $t('gtfsCheck.title') }}
              </td>

              <td>
                {{ $t('gtfsCheck.' + gtfsCheck) }}
              </td>
            </tr>

            <tr>
              <td class="details-table__label">
                {{ $t('team') }}
              </td>

              <td>
                <Team :team-id="device?.teams ? device.teams[0] : null" no-team-placeholder />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="device-header__column">
        <table class="details-table">
          <tbody>
            <tr>
              <td class="details-table__label">
                {{ $t('deviceId') }}
              </td>

              <td>
                {{ deviceId }}
              </td>
            </tr>

            <tr>
              <td class="details-table__label">
                {{ $t('deviceModel') }}
              </td>

              <td>
                {{ deviceModel }}
              </td>
            </tr>

            <tr>
              <td class="details-table__label">
                {{ $t('deviceOS') }}
              </td>

              <td>
                {{ deviceOS }}
              </td>
            </tr>

            <tr>
              <td class="details-table__label">
                {{ $t('address') }}
              </td>

              <td>
                <span>{{ address }}</span>
                &nbsp;
                <button class="btn btn-small" @click="addressLookup" @mousedown.prevent="">
                  <font-awesome-icon icon="fa-sync-alt" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="device-content">
      <div class="device-datepicker">
        <HeaderDatepicker v-model:value="selectedDate" class="stop-schedule__date" />
      </div>

      <div class="device-events">
        <div class="event-list">
          <div v-if="isLoading" class="event-list__table">
            <div class="event-list__row">
              <div class="event-list__cell event-noevent-text" />
            </div>

            <div class="event-list__row">
              <div class="event-list__cell">
                <span class="event-list__cell event-noevent-icon" />
              </div>
            </div>
          </div>

          <template v-else-if="enhancedEvents.length > 0">
            <template v-for="(segment, index) in enhancedEvents" :key="'segment_' + index">
              <div class="event-list__table">
                <div class="event-list__row">
                  <div
                    v-if="isHLPSegment(segment)"
                    class="event-list__cell event-list__cell--header"
                    :class="{
                      'text--color-orange border--left-orange': index === 0,
                      'text--opacity-50 border--top-grey': index > 0,
                    }"
                  >
                    {{ $t(segment.type) }}
                  </div>

                  <router-link
                    v-else
                    :to="{
                      name: GroupRoute.TRIP_DETAILED,
                      params: { tripId: segment.tripId },
                      query: { date: segment.startDate || query.date },
                    }"
                    class="event-list__cell event-list__cell--header"
                    :class="{
                      'text--color-orange border--left-orange': index === 0,
                      'border--top-grey': index > 0,
                    }"
                    style="text-decoration: none"
                  >
                    <span>{{ $t(segment.formattedTripName) }}</span>
                    <br />
                    <span v-if="segment.tripFilter" class="event-list__mode">
                      <template v-if="segment.tripFilter.mode === 'trip'">{{ $t('mode.trip') }}</template>
                      <template v-else>
                        {{
                          $t('mode.withId', {
                            mode: $t('mode.' + segment.tripFilter.mode),
                            id: segment.tripFilter.id,
                          })
                        }}
                      </template>
                    </span>
                  </router-link>
                </div>
              </div>

              <div
                v-for="(event, eventIndex) in segment.events"
                :key="'detail_' + index + '_' + eventIndex"
                class="event-list__table"
              >
                <div class="event-list__row event-list__cell">
                  <span
                    class="event-list__time"
                    :class="{
                      'border--left-orange': index === 0,
                      'border--left-transparent': index > 0,
                    }"
                  >
                    {{ timestampFormatHHMM(event.ts) }}
                  </span>

                  <span class="event-list__icon" :class="{ 'text--opacity-50': isHLPSegment(segment) }">
                    <img alt="event" :src="getEventIcon(event.type)" height="17" width="17" />
                  </span>

                  <span class="event-list__text" :class="{ 'text--opacity-50': isHLPSegment(segment) }">
                    {{ $t('eventType.' + event.type) }}
                    <span v-if="event.versionApp">{{ event.versionApp }}</span>
                    <span v-if="event.routeId">{{ event.routeId }}</span>
                    <span v-if="event.blockId">{{ event.blockId }}</span>
                  </span>
                </div>

                <div class="event-list__row event-list__cell border--left-orange">
                  <div
                    class="event-list__line"
                    :class="{
                      'border--left-orange': index === 0,
                      'border--left-no-border': index > 0,
                    }"
                  >
                    &nbsp;
                  </div>
                </div>
              </div>
            </template>
          </template>

          <!-- No events exist -->
          <div v-else class="event-list__table">
            <div class="event-list__row">
              <div class="event-list__cell event-list__cell--header event-list__noevent-text">
                {{ $t('noConnection') }}
              </div>
            </div>

            <div class="event-list__table">
              <div class="event-list__row">
                <span class="event-list__cell event-list__noevent-icon">
                  <img alt="event" :src="getEventIcon('noEvent')" width="100" />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div v-if="isLoading" class="event-details">
          {{ $t('dataLoading') }}
        </div>

        <div v-else-if="enhancedEvents.length > 0" class="event-details">
          <div class="event-details__header">
            <div class="event-details__column border--right-grey">
              <table class="details-table">
                <tbody>
                  <tr>
                    <td class="details-table__label">
                      {{ $t('route') }}
                    </td>

                    <td>
                      {{ routeName }}
                    </td>
                  </tr>

                  <tr>
                    <td class="details-table__label">
                      {{ $t('trip') }}
                    </td>

                    <td :title="tripId">
                      {{ formattedTripName }}
                    </td>
                  </tr>

                  <tr>
                    <td class="details-table__label">
                      {{ $t('mode.title') }}
                    </td>

                    <td>
                      {{ mode }}
                    </td>
                  </tr>

                  <tr v-if="currentDriver != null">
                    <td class="details-table__label">
                      {{ $t('currentDriver') }}
                    </td>

                    <td v-if="currentDriver">
                      {{ currentDriver }}
                    </td>

                    <td v-else>
                      {{ $t('unknownDriver') }}
                    </td>
                  </tr>

                  <tr v-if="currentVehicle != null">
                    <td class="details-table__label">
                      {{ $t('currentVehicle') }}
                    </td>

                    <td v-if="currentVehicle">
                      {{ currentVehicle }}
                    </td>

                    <td v-else>
                      {{ $t('unknownVehicle') }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="event-details__column">
              <table class="details-table">
                <tbody>
                  <tr>
                    <td class="details-table__label">
                      {{ $t('delay') }}
                    </td>

                    <td>
                      <DeviceDelay :delay="delay" />
                    </td>
                  </tr>

                  <tr>
                    <td class="details-table__label">
                      {{ $t('speed') }}
                    </td>

                    <td>
                      {{ speed == null ? '-' : speed + ' km/h' }}
                    </td>
                  </tr>

                  <tr>
                    <td class="details-table__label">
                      {{ $t('gpsPrecision') }}
                    </td>

                    <td :class="gpsPrecisionClass">
                      {{ gpsPrecision }}
                    </td>
                  </tr>

                  <tr>
                    <td class="details-table__label">
                      {{ $t('appVersion') }}
                    </td>

                    <td>
                      {{ appVersion }}
                    </td>
                  </tr>

                  <tr>
                    <td class="details-table__label">
                      {{ $t('latlng') }}
                    </td>
                    <td>{{ latlng.latitude ?? '-' }},{{ latlng.longitude ?? '-' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="event-details__map">
            <HistoryMap
              v-if="eventInReplay"
              :center="[eventInReplay.latlng[1], eventInReplay.latlng[0]]"
              :device="mapboxDevice"
              :stops="mapboxStops"
              :trip="mapboxTrip"
              :ts="timestamp"
            />
          </div>
        </div>

        <div v-else class="event-details">
          <div v-if="getLastKnownTimeConnectionISO() === null" class="event-details__no-event">
            {{ $t('noConnectionShort') }}
          </div>

          <div v-else class="event-details__no-event">
            <router-link
              :to="{
                name: GroupRoute.DEVICE_DETAILLED,
                params: { deviceId },
                query: { date: getLastKnownTimeConnectionISO() },
              }"
            >
              {{ $t('viewLastKnownPosition') }} :
              {{ getLastKnownTimeConnectionDate() }}
            </router-link>
          </div>
        </div>
      </div>

      <Timeline
        v-if="events.length > 0"
        class="device__timeline"
        :events="events"
        :tz="group.tz"
        :selected-date="selectedDate"
        @change="ts => (timestamp = ts)"
      />
    </div>

    <ModalMessageNew
      v-if="modalMessageNewShown"
      :recipients="[recipient]"
      @close="modalMessageNewShown = false"
    />
  </div>
</template>

<script>
import Geocoding from '@mapbox/mapbox-sdk/services/geocoding';
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { GroupRoute } from '@/libs/routing';
import DeviceDelay from '@/components/common/DeviceDelay.vue';
import HistoryMap from '@/components/map/HistoryMap.vue';
import { HighlightType } from '@/@types/mapbox';
import Btn from '@/components/ui/Btn.vue';
import HeaderDatepicker from '@/components/ui/HeaderDatepicker.vue';
import ModalMessageNew from '@/components/ui/ModalMessageNew.vue';
import Timeline from '@/components/ui/Timeline.vue';
import Team from '@/components/ui/SingleTeamSelector.vue';
import {
  dateObjToGtfsFormat,
  getISODate,
  secondsFormatTime,
  timestampFormatDateAndHHMM,
  timestampFormatHHMM,
} from '@/libs/helpers/dates';
import { eventStreamToStateStream } from '@/libs/helpers/objects';
import { formatDriver } from '@/store/drivers';

import { generateEvents, SegmentType } from './eventsStateMachine';
import { useVehiclesStore } from '@/store-pinia/vehicles';

dayjs.extend(utc);

const EVENT_ICON = new Map([
  ['blockSelection', new URL(`../../assets/img/event-prisedeservice-start.svg`, import.meta.url).href],
  ['connected', new URL(`../../assets/img/event-connected.svg`, import.meta.url).href],
  ['disconnected', new URL(`../../assets/img/event-disconnected.svg`, import.meta.url).href],
  ['HLPEnd', new URL(`../../assets/img/event-HLP-end.svg`, import.meta.url).href],
  ['HLPStart', new URL(`../../assets/img/event-HLP-start.svg`, import.meta.url).href],
  ['routeSelection', new URL(`../../assets/img/event-prisedeservice-start.svg`, import.meta.url).href],
  ['tripEnd', new URL(`../../assets/img/event-prisedeservice-end.svg`, import.meta.url).href],
  ['tripPending', new URL(`../../assets/img/event-trip-pending.svg`, import.meta.url).href],
  ['tripStart', new URL(`../../assets/img/event-prisedeservice-start.svg`, import.meta.url).href],
  ['updatedApp', new URL(`../../assets/img/version-app.svg`, import.meta.url).href],
  ['noEvent', new URL(`../../assets/img/event-noevent.svg`, import.meta.url).href],
]);

const mapboxGeocoder = Geocoding({
  accessToken: 'pk.eyJ1IjoicHlzYWUiLCJhIjoiY2s0Y2hrYTlxMG50ODNra2R6ZGVudTR5aiJ9.sccZsmomeJ-zdW21vHcSYQ',
});

export default {
  name: 'DeviceDetail',

  components: {
    Btn,
    Team,
    DeviceDelay,
    HeaderDatepicker,
    HistoryMap,
    Timeline,
    ModalMessageNew,
  },

  props: {
    /** @type {import('vue').Prop<{date?: string}>} */
    query: {
      type: Object,
      default: () => ({}),
    },

    deviceId: {
      required: true,
      type: String,
    },
  },

  data: () => ({
    vehiclesStore: useVehiclesStore(),
    GroupRoute,
    address: null,

    /** @type {Array<import('./eventsStateMachine').EventsSegment>} */
    enhancedEvents: [],

    events: [],
    formattedTripName: '-',

    loading: {
      device: false,
      events: false,
      enhanceEvents: false,
    },

    modalMessageNewShown: false,
    resumeDetailsShown: false,

    timerNow: {
      _id: null,
      ts: Date.now() / 1000,
    },

    timestamp: new Date().getTime() / 1000,
    shapePath: [],
  }),

  computed: {
    ...mapState({
      device(state) {
        return state.devices.list[this.deviceId];
      },

      lastChanges: state => state.devices.lastChanges,
      driversList: state => state.drivers.list,
    }),

    /** @return {Dictionary<import('@/store-pinia/vehicles').Vehicle>*/
    vehiclesList() {
      return this.vehiclesStore.list;
    },
    /** @return {import('@/store').Group} */
    group() {
      return this.$store.getters.group;
    },

    /** @return {?import('@/store/activity-log').ActivityLogEntry} */
    activityLogEntry() {
      return this.$store.getters['activityLog/getEntry'](this.deviceId, this.timestamp);
    },

    /** @return {?string} */
    appVersion() {
      if (this.eventInReplay) {
        return this.eventInReplay.app_version;
      }

      return null;
    },

    /**
     * Value can either be `null` when no activity log entry exists, `false`
     * when driver is not found in the list, or a formatted string.
     * @return {?string|false}
     */
    currentDriver() {
      if (this.activityLogEntry) {
        const driver = this.driversList[this.activityLogEntry.driver_id];
        if (this.activityLogEntry.anonymized) {
          return this.$t('anonymized');
        }
        if (!driver) return false;

        return formatDriver(driver);
      }

      return null;
    },

    /**
     * Value can either be `null` when no activity log entry exists, `false`
     * when vehicle is not found in the list, or a formatted string.
     * @return {?string|false}
     */
    currentVehicle() {
      if (this.activityLogEntry) {
        const vehicle = this.vehiclesList[this.activityLogEntry.vehicle_id];
        if (!vehicle) return false;

        return this.vehiclesStore.formatVehicle(vehicle);
      }

      return null;
    },

    /** @return {?number} */
    delay() {
      if (this.eventInReplay) {
        return this.eventInReplay.delay;
      }

      return null;
    },

    /** @return {?string} */
    deviceGtfs() {
      if (this.device) return this.device.gtfs_id;

      return null;
    },

    /** @return {?string} */
    deviceModel() {
      if (this.device && Object.prototype.hasOwnProperty.call(this.device, 'device_model')) {
        return this.device.device_model;
      }

      return null;
    },

    /** @return {?string} */
    deviceOS() {
      if (this.device && Object.prototype.hasOwnProperty.call(this.device, 'device_os')) {
        return this.device.device_os;
      }

      return null;
    },

    /**
     * @return {?import('@/store/devices').Event}
     */
    eventInReplay() {
      return this.events.find(event => this.timestamp <= event.ts && event.latlng);
    },

    /** @return {?number} */
    gpsPpm() {
      if (this.eventInReplay) {
        return this.eventInReplay.gps_ppm;
      }

      return null;
    },

    /** @return {string} */
    gpsPrecision() {
      let latlngAccuracy;
      if (this.eventInReplay) {
        latlngAccuracy = this.eventInReplay.latlng_accuracy;
      }
      const precision = [];
      if (latlngAccuracy != null && !Number.isNaN(latlngAccuracy)) {
        precision.push(`${Math.round(latlngAccuracy)} m`);
      }
      if (this.gpsPpm != null && !Number.isNaN(this.gpsPpm)) {
        precision.push(`${this.gpsPpm} pos/min`);
      }
      if (precision.length > 0) {
        return precision.join(' - ');
      }
      return '-';
    },

    /** @return {string} */
    gpsPrecisionClass() {
      if (this.gpsPpm != null && !Number.isNaN(this.gpsPpm)) {
        if (this.gpsPpm <= 10) {
          return 'text--color-red';
        }
        if (this.gpsPpm <= 50) {
          return 'text--color-orange';
        }
        return 'text--color-green';
      }

      return '';
    },

    /** @return {?string} */
    groupGtfs() {
      return this.$store.getters.group.current_file;
    },

    /** @return {string} */
    gtfsCheck() {
      if (!this.deviceGtfs || !this.groupGtfs) return 'notAvailable';

      if (this.deviceGtfs === this.groupGtfs) {
        return 'yes';
      }
      return 'no';
    },

    /** @return {string} */
    gtfsId() {
      return this.$store.getters['gtfs/getGtfsAt'](this.timestamp);
    },

    /** @return {{[routeId: string]: import('@/store/gtfs').Route}} */
    gtfsRoutes() {
      return this.$store.getters['gtfs/getCachedGtfsTable'](this.gtfsId, 'routes');
    },

    /** @return {{[tripId: string]: import('@/store/gtfs').Trip}} */
    gtfsTrips() {
      return this.$store.getters['gtfs/getCachedGtfsTable'](this.gtfsId, 'trips');
    },

    /** @return {boolean} */
    isDeviceConnected() {
      return !!this.$store.state.devices.online[this.deviceId];
    },

    /** @return {boolean} */
    isDeviceNameEmpty() {
      return !(this.device && this.device.name);
    },

    /** @return {boolean} */
    isLoading() {
      return Object.values(this.loading).some(v => v);
    },

    /** @return {{latitude: string | null, longitude: string | null}} */
    latlng() {
      if (!this.eventInReplay) return { latitude: null, longitude: null };
      return { latitude: this.eventInReplay.latlng[0], longitude: this.eventInReplay.latlng[1] };
    },

    /** @return {string|number} */
    lastDataReceived() {
      const deviceTs = this.device?.ts_system ?? this.device?.ts;

      if (Number.isNaN(deviceTs)) return '-';

      const timeDiff = secondsFormatTime(this.timerNow.ts - deviceTs);

      if (!timeDiff) {
        return timestampFormatDateAndHHMM(deviceTs, this.group.tz);
      }
      return timeDiff;
    },

    /** @return {{events: Array, id: string}} */
    mapboxDevice() {
      if (!this.device) return null;

      return { events: this.events, id: this.deviceId };
    },

    /** @return {Array<import('@/components/map/MapboxMap.vue').MapStop>} */
    mapboxStops() {
      if (!this.trip || !this.trip.stop_times) return [];

      const stopTimes = this.trip.stop_times;
      stopTimes.sort((a, b) => a.stop_sequence - b.stop_sequence);

      return Object.values(
        stopTimes.reduce((acc, stopTime) => {
          if (!acc[stopTime.stop_id]) {
            acc[stopTime.stop_id] = {
              id: stopTime.stop_id,
              highlight: false,
            };
          }

          return acc;
        }, /** @type {{[stopId: string]: import('@/components/map/MapboxMap.vue').MapStop}} */ ({})),
      );
    },

    /** @return {{id: string, highlight: HighlightType}} */
    mapboxTrip() {
      if (!this.trip) return null;

      return {
        id: this.tripId,
        highlight: HighlightType.MORE,
      };
    },

    /** @return {string} */
    mode() {
      if (!this.eventInReplay || !this.eventInReplay.trip_filter) {
        return '-';
      }

      const tripFilter = this.eventInReplay.trip_filter;

      if ('trip_id' in tripFilter) {
        if (tripFilter.trip_id === null) {
          return /** @type {string} */ (this.$t('HLP'));
        }
        return /** @type {string} */ (
          this.$t('mode.withId', {
            mode: this.$t('trip'),
            id: tripFilter.trip_id,
          })
        );
      }

      if ('block_id' in tripFilter) {
        return /** @type {string} */ (
          this.$t('mode.withId', {
            mode: this.$t('block'),
            id: tripFilter.block_id,
          })
        );
      }

      if ('route_id' in tripFilter) {
        const route = this.gtfsRoutes[tripFilter.route_id] || /** @type {import('@/store/gtfs').Route} */ {};
        return /** @type {string} */ (
          this.$t('mode.withId', {
            mode: this.$t('route'),
            id: route.route_short_name || route.route_long_name || tripFilter.route_id,
          })
        );
      }

      return '-';
    },

    /** @return {string} */
    name() {
      if (this.device && this.device.name) {
        return this.device.name;
      }
      return /** @type {string} */ (this.$t('deviceName'));
    },

    /** @return {{type: string, id: string}} */
    recipient() {
      return {
        type: 'device_id',
        id: this.deviceId,
      };
    },

    /** @return {string} */
    routeName() {
      if (this.trip) {
        const route = this.gtfsRoutes[this.trip.route_id];
        if (route) {
          // TODO: [Bug] Hides potential error: `route_id` in a trip, not found in routes?
          return route.route_short_name || route.route_long_name;
        }
      }

      return '-';
    },

    selectedDate: {
      /** @return {Date} */
      get() {
        if (this.query.date) return dayjs(this.query.date).utc().toDate();
        return new Date();
      },

      /** @param {Date} value */
      set(value) {
        this.$router.push({
          name: GroupRoute.DEVICE_DETAILLED,
          params: {
            groupId: /** @type {import('@/store').Store} */ (this.$store).getters.group._id,
            deviceId: this.deviceId,
          },
          query: {
            date: getISODate(value),
          },
        });
      },
    },

    /** @return {string} */
    selectedDateGtfs() {
      return dateObjToGtfsFormat(this.selectedDate);
    },

    /** @return {?number} */
    speed() {
      if (this.eventInReplay && this.eventInReplay.speed !== undefined) {
        return Math.round(this.eventInReplay.speed * 36) / 10;
      }

      return null;
    },

    /** @return {import('@/store/gtfs').Trip} */
    trip() {
      if (this.tripId && this.gtfsTrips[this.tripId]) {
        return this.gtfsTrips[this.tripId];
      }
      return /** @type {import('@/store/gtfs').Trip} */ ({}); // TODO: [Bug] Hides potential error: `trip_id` in a event, not found in trips?
    },

    /** @return {string} */
    tripId() {
      if (this.eventInReplay) {
        return this.eventInReplay.trip_id;
      }
      return '-'; // TODO: [Bug] This value is used to get data from GTFS, it should not have a fallback here.
    },
  },

  watch: {
    query: {
      deep: true,
      immediate: true,
      handler(query) {
        if (query.date !== undefined) {
          this.timestamp = new Date(query.date).getTime() / 1000;
        }
      },
    },

    lastChanges: {
      deep: true,
      handler() {
        let events = this.lastChanges.filter(event => event.device_id === this.deviceId);

        // Hot upates are only interesting for the day. Not for pasts dates.
        if (
          events.length > 0 &&
          new Date().toISOString().split('T')[0] === this.selectedDate.toISOString().split('T')[0]
        ) {
          events.sort((a, b) => a.ts - b.ts);
          let initState = /** @type {import('@/store/devices').Event} */ ({});
          if (this.events.length > 0) {
            initState = this.events[this.events.length - 1];
          }

          events = eventStreamToStateStream(events, initState);

          const someEventsHaveCoordonates = events.some(event => event.latlng && event.latlng.length === 2);
          if (someEventsHaveCoordonates) {
            this.events = this.events.concat(events);
          }
        }
      },
    },

    async deviceId() {
      this.resetData();
      await Promise.all([this.fetchDevice(), this.fetchEvents()]);
      await this.addressLookup();
    },

    events: {
      deep: true,
      handler() {
        this.loading.enhancedEvents = true;
        this.generateEnhancedEvents(this.events);
        this.loading.enhancedEvents = false;
      },
    },

    gtfsId: {
      immediate: true,
      handler() {
        if (this.gtfsId) {
          this.$store.dispatch('gtfs/getRoutesMap', { gtfsId: this.gtfsId });
          this.$store.dispatch('gtfs/getStopsMap', { gtfsId: this.gtfsId });
          this.$store.dispatch('gtfs/getTripsMap', { gtfsId: this.gtfsId });
        }
      },
    },

    async selectedDate() {
      this.resetData();
      await Promise.all([this.fetchDevice(), this.fetchEvents()]);
      await this.addressLookup();
    },

    selectedDateGtfs: {
      immediate: true,
      handler() {
        this.loadActivityLog();
      },
    },

    async tripId() {
      if (this.tripId) {
        this.formattedTripName = await this.$store.dispatch('gtfs/formatTripName', {
          tripId: this.tripId,
          date: new Date(this.timestamp * 1000),
        });
      } else {
        this.formattedTripName = '-';
      }
    },
  },

  async created() {
    await Promise.all([this.fetchDevice(), this.fetchEvents(), this.loadDrivers(), this.loadVehicles()]);
    await this.addressLookup();
    this.timerNow._id = setInterval(() => {
      this.timerNow.ts = Date.now() / 1000;
    }, 1000);
  },

  beforeUnmount() {
    clearInterval(this.timerNow._id);
  },

  methods: {
    async addressLookup() {
      if (!this.device || (!this.eventInReplay && !this.device.history_last_latlng_ts)) return;
      let query = null;
      if (!this.eventInReplay) {
        const dateGtfs = new Date(this.device.history_last_latlng_ts * 1000)
          .toISOString()
          .split('T')[0]
          .replaceAll('-', '');
        const events = await this.$store.dispatch('devices/getEventsOf', {
          deviceId: this.deviceId,
          dateGtfs,
        });
        if (!events) return;
        const lastEvent = events.find(e => this.device.history_last_latlng_ts <= e.ts && e.latlng);
        if (!lastEvent) return;
        query = [lastEvent.latlng[1], lastEvent.latlng[0]];
      } else {
        query = [this.eventInReplay.latlng[1], this.eventInReplay.latlng[0]];
      }
      await mapboxGeocoder
        .reverseGeocode({ query, limit: 1 })
        .send()
        .then(response => {
          const { features } = response.body;
          if (features.length > 0) {
            this.address = features[0].place_name;
          }
        });
    },

    /**
     * Collect device data, events linked to device, and address.
     */
    async fetchDevice() {
      this.loading.device = true;
      try {
        await this.$store.dispatch('devices/getDevice', { deviceId: this.deviceId });
      } catch (e) {
        // Check if error 404, it means user has no access to this ressource. (team rights)
        if (e?.response?.status === 404) {
          this.$router.replace({ name: 'unauthorizedInternal' });
        }
      }
      this.loading.device = false;
    },

    /**
     * Collect all events linked to a deviceId.
     */
    async fetchEvents() {
      this.loading.events = true;
      const dateGtfs = dateObjToGtfsFormat(this.selectedDate);

      const events = await this.$store.dispatch('devices/getEventsOf', {
        deviceId: this.deviceId,
        dateGtfs,
      });

      // Check if some events have valid coordinates to eliminate devices connected all day with no GPS
      const someEventsHaveCoordonates = events.some(event => event.latlng && event.latlng.length === 2);
      if (!someEventsHaveCoordonates) {
        this.loading.events = false;
        return;
      }

      // Check if date has not changed during loading
      if (dateGtfs === dateObjToGtfsFormat(this.selectedDate)) {
        if (events) {
          events.sort((a, b) => a.ts - b.ts);
          this.events = events;
        }
      }
      this.loading.events = false;
    },

    /**
     * Pass all events through a state machine to generate the list of enhanced events.
     * @param {Array<import('@/store/devices').Event>} events
     */
    generateEnhancedEvents(events) {
      const MAX_GAP = 3;
      const cleanedHistory = [];
      const queuedEvents = [];
      let currentTripId;

      events.forEach(e => {
        // Queue hlp between trips until limit.
        if (currentTripId && e.trip_id == null && queuedEvents.length <= MAX_GAP) {
          queuedEvents.push(e);
          return;
        }

        // If event returns to previous trip, remove wrong events
        if (currentTripId && e.trip_id === currentTripId) {
          queuedEvents.splice(0);
        }

        // Merge queued events into history
        if (queuedEvents.length > 0) {
          cleanedHistory.push(...queuedEvents.splice(0));
        }

        cleanedHistory.push(e);
        currentTripId = e.trip_id;
      });

      const eventsSegments = generateEvents(
        {},
        cleanedHistory,
        /** @type {import('@/store').Store} */ (this.$store).getters.group.delay_device_online,
      );

      // Sort enhancedEvents from last to first
      eventsSegments.forEach(s => {
        if (s.type === SegmentType.TRIP) {
          s.formattedTripName = s.tripId;
          this.$store
            .dispatch('gtfs/formatTripName', {
              tripId: s.tripId,
              date: new Date(s.ts * 1000),
            })
            .then(name => {
              s.formattedTripName = name;
            });
        }

        s.events.reverse();
      });
      eventsSegments.reverse();

      this.enhancedEvents = eventsSegments;
    },

    /**
     * Get event icon path & name from a key.
     * @params {string} key
     * @return {string} - Icon path & name.
     */
    getEventIcon(key) {
      return EVENT_ICON.get(key);
    },

    getLastKnownTimeConnectionISO() {
      if (!this.device || !this.device.history_last_latlng_ts) return null;
      return new Date(this.device.history_last_latlng_ts * 1000).toISOString().split('T')[0];
    },

    getLastKnownTimeConnectionDate() {
      if (!this.device || !this.device.history_last_latlng_ts) return null;
      return new Date(this.device.history_last_latlng_ts * 1000).toLocaleDateString();
    },

    /**
     * Return true when segment is HLP.
     * @return {boolean}
     */
    isHLPSegment(segment) {
      return segment.type === SegmentType.HLP;
    },

    /**
     * Is this.selectedDate a today date?
     * @return {boolean} true when value is today
     */
    isSelectedDateATodayDate() {
      const selectedDate = this.selectedDate.toISOString().split('T')[0];
      const today = new Date().toISOString().split('T')[0];

      return selectedDate === today;
    },

    loadActivityLog() {
      this.$store.dispatch('activityLog/loadEntries', this.selectedDateGtfs);
    },

    /**
     * Load drivers list for assigned values.
     */
    async loadDrivers() {
      await this.$store.dispatch('drivers/loadList');
    },

    /**
     * Load vehicles list for assigned values.
     */
    async loadVehicles() {
      await this.vehiclesStore.loadList();
    },

    /**
     * Reset all data before collecting them.
     */
    resetData() {
      this.enhancedEvents = [];
      this.events = [];
      this.shapePath = [];
    },

    /**
     * Format timestamp to group local time.
     * @param {number} ts
     * @return {string}
     */
    timestampFormatHHMM(ts) {
      return timestampFormatHHMM(ts, { tz: this.group.tz });
    },

    /**
     * Hide or show resumedetails.
     */
    toggleDetails() {
      this.resumeDetailsShown = !this.resumeDetailsShown;
    },
  },
};

/**
 * @typedef {Object} MapStateObj
 * @property {() => ?import('@/store/devices').Device} device
 * @property {() => Array<import('@/store/devices').Event>} lastChanges
 * @property {() => Object.<string, import('@/store/drivers').Driver>} driversList
 * @property {() => Object.<string, import('@/store-pinia/vehicles').Vehicle>} vehiclesList
 */
</script>

<style scoped lang="scss">
.border {
  &--left-grey {
    border-left: 1px solid $border;
  }

  &--left-no-border {
    border-left: none;
  }

  &--left-orange {
    border-left: 3px solid $warn;
  }

  &--left-transparent {
    border-left: 3px solid transparent;
  }

  &--right-grey {
    border-right: 1px solid $border;
  }

  &--top-grey {
    border-top: 1px solid $border;
  }
}

.details-table {
  &__label {
    padding-right: 0.5em;
    color: $text-neutral;
    text-align: right;
  }
}

.device {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px;
}

.device-content {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  min-height: 0; // By default won't flex-shrink below content size
  border: 1px solid $border;
  box-shadow: 3px 0 5px $border;
}

.device-datepicker {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  border-bottom: 1px solid $border;
  background-color: rgb(79 79 79 / 5%); /* $text-dark-variant / opacity 5% */
  color: $text-dark-variant;
  box-shadow: 0 -1px 5px $border;
  font-weight: $font-weight-semi-bold;
}

.device-events {
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  min-height: 0; // By default won't flex-shrink below content size
  margin: 10px;
}

.device-header {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 1em;

  &__column {
    display: flex;
    flex-grow: 1;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-around;

    &--justify-left {
      justify-content: flex-start;
    }
  }

  &__id-elements {
    padding-left: 20px;
  }

  &__name {
    color: $secondary;
    font-weight: $font-weight-semi-bold;
    font-size: 18px;

    &--empty {
      color: $text-neutral;
    }
  }
}

.device__timeline {
  position: relative;
  margin: 4px 10px 10px;
}

.dot-connection {
  display: inline-block;
  width: 0.7em;
  height: 0.7em;
  border-radius: 50%;
  background-color: $background;

  &--connected {
    background-color: $primary-light;
  }
}

.event-details {
  display: flex;
  flex: 2;
  flex-flow: column nowrap;
  margin-left: 10px;

  &__header {
    display: flex;
    flex-flow: row nowrap;
  }

  &__column {
    display: flex;
    flex: 1;
    justify-content: space-around;
    margin-bottom: 0.5em;
  }

  &__no-event {
    margin: 1em;
  }

  &__map {
    position: relative;
    flex: 1;
  }
}

.event-list {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  overflow-x: hidden;
  overflow-y: auto;
  max-width: 400px;
  border: 1px solid $border;
  font-family: $font-poppins;

  &__cell {
    display: table-cell;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    text-align: left;

    &--header {
      font-weight: $font-weight-semi-bold;
    }
  }

  &__icon {
    padding-left: 10px;

    > img {
      vertical-align: bottom;
    }
  }

  &__line {
    display: table-cell;
  }

  &__mode {
    color: $text-dark-variant;
    font-size: 13px;
  }

  &__noevent-icon {
    text-align: center;
  }

  &__noevent-text {
    padding-top: 40px;
    text-align: center;
  }

  &__row {
    display: table-row;
  }

  &__table {
    display: table;
    width: 100%;
  }

  &__text {
    padding-left: 10px;
    color: $text-dark-variant;
  }

  &__time {
    padding-left: 40px;
    color: $text-dark;
  }
}

.text {
  &--color-red {
    color: $danger;
  }

  &--color-orange {
    color: $warn;
  }

  &--color-green {
    color: $primary-light;
  }

  &--opacity-50 {
    opacity: 0.5;
  }
}
</style>

<i18n locale="fr">
{
  "eventType": {
    "blockSelection": "Sélection de service :",
    "connected": "Connecté",
    "disconnected": "Déconnecté",
    "HLPEnd": "Fin Haut-le-pied",
    "HLPStart": "Début Haut-le-pied",
    "routeSelection": "Sélection de ligne :",
    "tripEnd": "Fin de course",
    "tripPending": "Acheminement",
    "tripStart": "Début de course",
    "updatedApp": "MAJ version"
  },
  "gtfsCheck": {
    "title": "GTFS à jour",
    "no": "Non",
    "notAvailable": "Non renseigné",
    "yes": "Oui"
  },
  "mode": {
    "title": "Mode",
    "withId": "{mode} : {id}",
    "trip": "Mode course",
    "block": "Mode service",
    "route": "Mode ligne"
  },
  "address": "Dernière adresse",
  "anonymized": "Donnée anonymisée",
  "appVersion": "Version de l'app",
  "currentDriver": "Conducteur",
  "currentVehicle": "Véhicule",
  "block": "Service",
  "connected": "Connecté",
  "contact": "Contacter",
  "dataLoading": "Chargement des données...",
  "delay": "Avance-Retard",
  "deviceId": "Identifiant de l'appareil",
  "deviceModel": "Modèle de l'appareil",
  "deviceName": "Nom de l'appareil non renseigné",
  "deviceOS": "Version de l'OS",
  "disconnected": "Déconnecté",
  "gpsPrecision": "Précision GPS",
  "HLP": "Haut-le-pied",
  "lastDataReceived": "Dernière donnée reçue",
  "latlng": "Latitude, longitude",
  "noConnection": "Aucune connexion pour la journée sur cet appareil",
  "noConnectionShort": "Aucune connexion",
  "route": "Ligne",
  "speed": "Vitesse",
  "team": "Équipe",
  "trip": "Course",
  "viewLastKnownPosition": "Visualiser la dernière position connue"
}
</i18n>

<i18n locale="en">
{
  "eventType": {
    "blockSelection": "Service selection:",
    "connected": "Connected",
    "disconnected": "Disconnected",
    "HLPEnd": "End of dead running",
    "HLPStart": "Start of dead running",
    "routeSelection": "Route selection:",
    "tripEnd": "Trip end ",
    "tripPending": "Routing",
    "tripStart": "Trip start",
    "updatedApp": "Updated ap"
  },
  "gtfsCheck": {
    "title": "updated GTFS",
    "no": "No",
    "notAvailable": "Not available",
    "yes": "Yes"
  },
  "mode": {
    "title": "Mode",
    "withId": "{mode}: {id}",
    "trip": "Service mode",
    "block": "Duty mode",
    "route": "Route mode"
  },
  "address": "Last address",
  "anonymized": "Anonymized data",
  "appVersion": "App version",
  "currentDriver": "Driver",
  "currentVehicle": "Vehicle",
  "block": "Duty",
  "connected": "Connected",
  "contact": "Contact",
  "dataLoading": "Data loading...",
  "delay": "Delay",
  "deviceId": "Device ID",
  "deviceModel": "Device Model",
  "deviceName": "Uncompleted device name",
  "deviceOS": "OS version",
  "disconnected": "Disconnected",
  "gpsPrecision": "GPS precision",
  "HLP": "Dead running",
  "lastDataReceived": "Last data received",
  "latlng": "Latitude, Longitude",
  "noConnection": "No connection on this day for the device",
  "noConnectionShort": "No connection ",
  "route": "Line",
  "speed": "Speed",
  "team": "Team",
  "trip": "Trip",
  "viewLastKnownPosition": "View the last known position"
}
</i18n>

<i18n locale="cz">
{
  "eventType": {
    "blockSelection": "Výběr jízd:",
    "connected": "Připojeno",
    "disconnected": "Odpojeno",
    "HLPEnd": "Konec jízdy do depa",
    "HLPStart": "Začátek jízdy do depa",
    "routeSelection": "Výběr trasy:",
    "tripEnd": "Konec jízdy",
    "tripPending": "Pojíždění",
    "tripStart": "Začátek jízdy",
    "updatedApp": "Aplikace je aktuální"
  },
  "gtfsCheck": {
    "title": "GTFS je aktuální",
    "notAvailable": "Nedostupné",
    "yes": "Ano",
    "no": "Ne"
  },
  "mode": {
    "withId": "{mode}: {id}",
    "trip": "Režim jízdy",
    "block": "Režim přidělení",
    "route": "Režim trasy",
    "title": "Režim"
  },
  "address": "Poslední adresa",
  "appVersion": "Verze aplikace",
  "connected": "Připojeno",
  "contact": "Kontaktovat",
  "dataLoading": "Načítám data...",
  "deviceId": "ID zařízení",
  "deviceModel": "Model zařízení",
  "deviceName": "Nekompletní název zařízení",
  "deviceOS": "verze OS",
  "disconnected": "Odpojeno",
  "gpsPrecision": "přesnost GPS",
  "lastDataReceived": "Poslední přijatá data",
  "latlng": "Šířka, Délka",
  "noConnection": "Pro tento den nemá zařízení žádná připojení",
  "noConnectionShort": "Žádná připojení",
  "viewLastKnownPosition": "Zobrazit poslední známou polohu",
  "currentDriver": "Řidič",
  "block": "Jízda",
  "trip": "Jízda",
  "HLP": "Na cestě z/do depa",
  "currentVehicle": "Vozidlo",
  "speed": "Rychlost",
  "route": "Linka",
  "team": "Tým",
  "delay": "Zpoždění"
}
</i18n>

<i18n locale="de">
{
  "eventType": {
    "blockSelection": "Fahrt-Auswahl:",
    "connected": "Verbunden",
    "disconnected": "Nicht verbunden",
    "HLPEnd": "Ende der Busdepot-Fahrt",
    "HLPStart": "Start der Busdepot-Fahrt",
    "routeSelection": "Strecken-Auswahl",
    "tripEnd": "Fahrtende",
    "tripPending": "Routing",
    "tripStart": "Fahrtbeginn",
    "updatedApp": "Aktualisierte App"
  },
  "gtfsCheck": {
    "title": "GTFS aktualisiert",
    "notAvailable": "Nicht verfügbar",
    "yes": "Ja",
    "no": "Nein"
  },
  "mode": {
    "withId": "{mode}: {id}",
    "trip": "Fahrtmodus",
    "block": "Zuteilungsmodus",
    "route": "Streckenmodus",
    "title": "Modus"
  },
  "address": "Letzte Adresse",
  "appVersion": "App-Version",
  "connected": "Verbunden",
  "contact": "Kontakt",
  "dataLoading": "Daten werden geladen ...",
  "deviceId": "Geräte-ID",
  "deviceModel": "Geräte-Modell",
  "deviceName": "Unvollständiger Gerätename",
  "deviceOS": "Betriebssystem-Version",
  "disconnected": "Nicht verbunden",
  "gpsPrecision": "GPS-Genauigkeit",
  "lastDataReceived": "Letzte empfangene Daten",
  "latlng": "Breitengrad, Längengrad",
  "noConnection": "Keine Verbindung an diesem Tag für dieses Gerät",
  "noConnectionShort": "Keine Verbindung",
  "viewLastKnownPosition": "Letzte bekannte Position ansehen",
  "currentDriver": "Fahrer",
  "block": "Fahrt",
  "trip": "Fahrt",
  "HLP": "Fahrt zum oder vom Busdepot",
  "currentVehicle": "Fahrzeug",
  "speed": "Geschwindigkeit",
  "route": "Strecke",
  "team": "Team",
  "delay": "Verspätung"
}
</i18n>

<i18n locale="es">
{
  "eventType": {
    "blockSelection": "Selección de servicio:",
    "connected": "Conectado",
    "disconnected": "Desconectado",
    "HLPEnd": "Fin del periodo sin servicio",
    "HLPStart": "Inicio del periodo sin servicio",
    "routeSelection": "Selección de ruta:",
    "tripEnd": "Fin del viaje",
    "tripPending": "Buscando ruta",
    "tripStart": "Inicio del viaje",
    "updatedApp": "Aplicación actualizada"
  },
  "gtfsCheck": {
    "title": "GTFS actualizado",
    "notAvailable": "No disponible",
    "yes": "Sí",
    "no": "No"
  },
  "mode": {
    "withId": "{mode}: {id}",
    "trip": "Modo de servicio",
    "block": "Modo de asignación",
    "route": "Modo de ruta",
    "title": "Modo"
  },
  "address": "Última dirección",
  "appVersion": "Versión de la aplicación",
  "connected": "Conectado",
  "contact": "Contacto",
  "dataLoading": "Cargando datos...",
  "deviceId": "ID de dispositivo",
  "deviceModel": "Modelo de dispositivo",
  "deviceName": "Nombre de dispositivo no completado",
  "deviceOS": "Versión del sistema operativo",
  "disconnected": "Desconectado",
  "gpsPrecision": "Precisión del GPS",
  "lastDataReceived": "Últimos datos recibidos",
  "latlng": "Latitud y longitud",
  "noConnection": "No se ha efectuado ninguna conexión desde este dispositivo en este día",
  "noConnectionShort": "Sin conexión",
  "viewLastKnownPosition": "Ver la última posición conocida",
  "currentDriver": "Conductor",
  "block": "Servicio",
  "trip": "Servicio",
  "HLP": "Sin pasajeros",
  "currentVehicle": "Vehículo",
  "speed": "Velocidad",
  "route": "Línea",
  "team": "Equipo",
  "delay": "Retraso"
}
</i18n>

<i18n locale="it">
{
  "eventType": {
    "blockSelection": "Selezione del servizio:",
    "connected": "Connesso",
    "disconnected": "Disconnesso",
    "HLPEnd": "Fine del tragitto da o verso il deposito",
    "HLPStart": "Inizio del tragitto da o verso il deposito",
    "routeSelection": "Selezione del percorso:",
    "tripEnd": "Fine del viaggio",
    "tripPending": "Percorso",
    "tripStart": "Inizio del viaggio",
    "updatedApp": "App aggiornata"
  },
  "gtfsCheck": {
    "title": "GTFS aggiornato",
    "notAvailable": "Non disponibile",
    "yes": "Sì",
    "no": "No"
  },
  "mode": {
    "withId": "{mode}: {id}",
    "trip": "Modalità di servizio",
    "block": "Modalità di assegnazione",
    "route": "Modalità di percorso",
    "title": "Modalità"
  },
  "address": "Ultimo indirizzo",
  "appVersion": "Versione dell'app",
  "connected": "Connesso",
  "contact": "Contatta",
  "dataLoading": "Caricamento dei dati in corso...",
  "deviceId": "ID dispositivo",
  "deviceModel": "Modello del dispositivo",
  "deviceName": "Nome del dispositivo non completo",
  "deviceOS": "Versione OS",
  "disconnected": "Disconnesso",
  "gpsPrecision": "Precisione del GPS",
  "lastDataReceived": "Ultimi dati ricevuti",
  "latlng": "Latitudine, longitudine",
  "noConnection": "Nessuna connessione disponibile per il dispositivo in questo giorno",
  "noConnectionShort": "Nessuna connessione",
  "viewLastKnownPosition": "Visualizza l'ultima posizione nota",
  "currentDriver": "Autista",
  "block": "Servizio",
  "trip": "Servizio",
  "HLP": "In tragitto da o verso il deposito",
  "currentVehicle": "Veicolo",
  "speed": "Velocità",
  "route": "Linea",
  "team": "Team",
  "delay": "Ritardo"
}
</i18n>

<i18n locale="pl">
{
  "eventType": {
    "blockSelection": "Wybór usługi:",
    "connected": "Połączono",
    "disconnected": "Rozłączono",
    "HLPEnd": "Koniec przejazdów bez pasażerów",
    "HLPStart": "Początek przejazdów bez pasażerów",
    "routeSelection": "Wybór trasy:",
    "tripEnd": "Koniec podróży",
    "tripPending": "Trasowanie",
    "tripStart": "Początek podróży",
    "updatedApp": "Zaktualizowana apka"
  },
  "gtfsCheck": {
    "title": "zaktualizowany GTFS",
    "notAvailable": "Niedostępne",
    "yes": "Tak",
    "no": "Nie"
  },
  "mode": {
    "withId": "{mode}: {id}",
    "trip": "Tryb usługi",
    "block": "Tryb przydziału",
    "route": "Tryb trasy",
    "title": "Tryb"
  },
  "address": "Ostatni adres",
  "appVersion": "Wersja apki",
  "connected": "Połączono",
  "contact": "Kontakt",
  "dataLoading": "Wczytywanie danych...",
  "deviceId": "Ident. urządzenia",
  "deviceModel": "Model urządzenia",
  "deviceName": "Niedokończona nazwa urządzenia",
  "deviceOS": "Wersja sys. oper.",
  "disconnected": "Rozłączono",
  "gpsPrecision": "Precyzja GPS",
  "lastDataReceived": "Ostatnio otrzymane dane",
  "latlng": "Szer., dł. geogr.",
  "noConnection": "Brak połączenia w tym dniu dla urządzenia",
  "noConnectionShort": "Brak połączenia",
  "viewLastKnownPosition": "Zobacz ostatnią znaną pozycję",
  "currentDriver": "Kierowca",
  "block": "Usługa",
  "trip": "Usługa",
  "HLP": "Przejazdy bez pasażerów",
  "currentVehicle": "Pojazd",
  "speed": "Prędkość",
  "route": "Linia",
  "team": "Zespół",
  "delay": "Opóźnienie"
}
</i18n>
