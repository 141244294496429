<template>
  <div class="trip-info" :class="{ 'trip-info__empty': params.length === 0 }">
    <div v-for="(line, index) in dividedParams" :key="index" class="trip-info__line">
      <div v-for="(param, index2) in line" :key="index2" class="trip-info__element">
        <label :title="$t(`tripDetailParam.${param}`)" class="trip-info__label">
          {{ $t(`tripDetailParam.${param}`) }}
        </label>
        <TripInfoItem
          :info-type="param"
          :date="date"
          :trip-id="trip.id"
          :info="getInfo(param, trip, date, groupId, groupTz)"
          @showModal="showModal"
          @redirectToModificationPage="$emit('redirectToModificationPage')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { DefaultSelectedParams, getInfo, TripDetailParams } from './TripInfoShared.js';
import TripInfoItem from './TripInfoItem.vue';

export default {
  name: 'TripInfo',

  components: { TripInfoItem },

  props: {
    date: {
      type: Date,
      required: true,
    },

    groupId: {
      type: String,
      required: true,
    },

    /** @type {import('vue').PropOptions<import('@/api').TripListItem} */
    trip: {
      type: Object,
      required: true,
    },
  },

  emits: ['redirectToModificationPage', 'showModal'],

  data: () => ({
    DefaultSelectedParams,
    getInfo,
    TripDetailParams,

    /** @type {{ [key: number]: import('@/api').TripListItem }} */
    dividedParams: {},
    /** @type {Array<TripDetailParams>} */
    params: [],
  }),

  computed: {
    /** @return {string} */
    groupTz() {
      return this.$store.getters.group.tz;
    },
  },

  created() {
    this.setParams();
    this.divideParams();
  },

  methods: {
    divideParams() {
      const lineSize = 3;
      const dividedParams = {};
      for (let i = 0; i < this.params.length; i += lineSize) {
        const line = this.params.slice(i, i + lineSize);
        dividedParams[i] = line;
      }
      this.dividedParams = dividedParams;
    },

    setParams() {
      const localStorageParam = JSON.parse(localStorage.getItem('tripDetailedPage/config'));
      if (localStorageParam) {
        this.params = JSON.parse(localStorage.getItem('tripDetailedPage/config'));
      } else {
        this.params = Object.keys(TripDetailParams).reduce((acc, elem) => {
          if (Object.prototype.hasOwnProperty.call(DefaultSelectedParams, elem)) {
            acc.push(TripDetailParams[elem]);
          }
          return acc;
        }, []);
      }
    },

    showModal(modalType) {
      this.$emit('showModal', modalType);
    },

    updateListFromLocalStorage() {
      this.setParams();
      this.divideParams();
    },
  },
};
</script>

<style lang="scss">
.trip-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 10px;
  border: 1px solid $border;
  border-radius: 8px;
  background-color: $canvas;

  &__line {
    display: flex;
  }

  &__empty {
    display: none;
  }

  &__label {
    display: inline-block;
    overflow: hidden;
    width: 120px;
    min-width: 120px;
    color: $text-neutral;
    font-weight: $font-weight-light;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__element {
    display: flex;
    align-items: center;
    width: 33%;
    min-height: 25px;
    margin: 5px 0;
  }
}
</style>
